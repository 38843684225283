<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.toppanel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Toppanel | {{panelname}}</div>

               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.FILTER_KIESREGIO}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content" v-if="regions">

                    <div v-for="region in regions" :key="region.id">                                                        
                         <a class="ghost button mb10" v-on:click="setregion(region)">{{region.name}}</a>
                    </div>


          
               </div>


        

          </div>

     </transition>
  
</template>

<script>

import axios from "axios";

export default {

 
     data() {
          return{
               panelname: 'FilterAreaAreaRegions',
               regions: null
         
          
               
          }
     },

     components: {


     },
     methods:{

            
          setregion(region) {
               
               this.setactiveproperty('filterarearegion',region);
               
               this.setactiveproperty('filterareaclub',null);

               this.setactiveproperty('toppanel','')

          }




     },

    computed: 
    {

          currentFilterCountry() {

               return this.$store.state.active.filterareacountry;

          }


    },

 watch: {

          currentFilterCountry()  {

                   // Wanneer het land id verandert, de nieuwe regios ophalen.
               if (this.$store.state.config.debug==1) 
               {
               console.log('getregions');                        
               }
               

               if (this.$store.state.active.filterareacountry)
               {
                    var post = new Object();
                    post.task = 'getregions';
                    post.countryID = this.$store.state.active.filterareacountry.id
                    
                    axios.post( this.$store.state.config.apiserver + 'clubs/panels/SearchFilter.php',post)
                    .then(response => {               
                         
                         this.regions = response.data;

                    })
                    .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    });


               }
         
          },




     },     


  
}
</script>

 
<style scoped>

</style>
