<template>

     <div v-if="item" class="panel_section" >


          <div class="row">

               <div class="links"> 
                    <img class="profilepicture" :src="'https://www.hockeydo.com/admin/' + item.logo"/> 
               </div>     

               <div class="midden heelklein tekstgrijs">
                    <span class="dik tekstzwart">{{ item.name }} </span>  {{ item.id }} &bull;  {{ calctime }} &bull; {{ country }}
               </div>


        

          
          </div>   
          
          <div class="row">

               <div class="links">


               </div>
               <div class="midden">
                    
                    <h2>{{ item.title }}</h2>
                    {{ item.description }}
                    <a target="_BLANK" :href="item.link">link</a>


               </div>




          </div>
                    
          

          
          
     </div>
     
</template>


<script>
import axios from "axios";

export default{

     data(){
          return {    
               item: null           
          }
     },
     props: ["feeditemid"],
     computed:{          


          country() {
               return this.item['country_' + this.languagewebsite];

          },
  

          calctime() 
          {
               var now = Date.now() /1000 |0             
               var created = parseInt((new Date(this.item.pubdate).getTime() / 1000).toFixed(0)); 
               var diff = now-created;

               var minutes = Math.floor(diff/60);         
               if (minutes<61) {
                    
                    return minutes + ' ' + this.$store.state.translate.POSTPOST_MINUTEN;

               } 
               

               var hours = Math.floor(minutes/60);
               if (hours<25)
               {    
                    if (hours==1) {
                         return hours + ' ' + this.$store.state.translate.POSTPOST_UUR;
                    } else
                    {
                         return hours + ' ' + this.$store.state.translate.POSTPOST_UREN;
                    }
                    
               }

               var days = Math.floor(hours/24);
               if (days<31)
               {    
                    if (days==1) {
                         return days + ' ' + this.$store.state.translate.POSTPOST_DAG;
                    } else
                    {
                         return days + ' ' + this.$store.state.translate.POSTPOST_DAGEN;
                    }

               }

               var months = Math.floor(days/30);
               if (months<12)
               {
                    if (months==1) {
                         return months + ' ' + this.$store.state.translate.POSTPOST_MAAND;
                    } else
                    {
                         return months + ' ' + this.$store.state.translate.POSTPOST_MAANDEN;
                    }

               }
               
               var years = Math.floor(months/12);    

               if (years==1) {
                    return years + ' ' + this.$store.state.translate.POSTPOST_JAAR;
               } else
               {
                    return years + ' ' + this.$store.state.translate.POSTPOST_JAREN;
               }
               
             



          },
   
     

          
     },


     methods: {

   

          fetchfeeditem()  
          {
             

               if (this.$store.state.config.debug==1) 
               {
                    console.log('fetchfeeditem');
               }    
               
      
               var post = new Object();
               post.task = 'fetchfeeditem';
               post.itemid =  this.feeditemid;  


               axios.post( this.$store.state.config.apiserver + 'users/panels/BookmarksFeeditems.php', post)
               .then(response => {
                    this.item = response.data;
                    // console.log(response.data);
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          },


     },
     mounted() {
          this.fetchfeeditem();
     }
}
</script>

