export default {

     increaseloading(state){

          if (state.active.loading){
               state.active.loading +=1;
          } else {
               state.active.loading = 1;
          }


     },

     setroute(state,route){

          state.route = route
          
     },
     setroutename(state,name){

          if (name) {
               state.route.section = name.toLowerCase();
          }

          
          
     },


     setcurrentproperty(state,payload){

          state.current[payload.property] = payload.value;
          
     },
     setactiveproperty(state,payload){

          state.active[payload.property] = payload.value;
          
     },
     setstaticproperty(state,payload){

          state.static[payload.property] = payload.value;
          
     },
     setuser(state,user) {
          state.user = user;
     },
     setlanguages(state,languages){
          state.languages = languages;
     },

     setuseractivities(state,items) {
          state.useractivities = items;
     },

     setuserfunctions(state,items) {
          state.userfunctions = items;
     },

     setpostcats(state,items) {
          state.postcats = items;
     },


     setlocations(state,items) {
          state.locations = items;
     },

     settranslate(state,translate) {
          state.translate = translate;
     },

     setusergroups(state,groups) {
          state.usergroups = groups;
     },
          setCurrentRegion(state,region){
          state.current.region = region;  
     },
     setCurrentCountry(state,country){
          state.current.country = country;      
     },
     setCurrentClub(state,club){
          state.current.club = club;          
     },
     setCurrentContinent(state,continent){
          state.current.continent = continent;          
     },
       
     setUserClub(state,club) {
          state.user.club = club;
     },




   



}