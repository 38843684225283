import { createRouter, createWebHistory } from 'vue-router'
import ClubsView from '@/components/clubs/ClubsView.vue'
import PostsView from '@/components/posts/PostsView.vue'
import GamesView from '@/components/games/GamesView.vue'
import WeView from '@/components/we/WeView.vue'
import HockeydoView from '@/components/hockeydo/HockeydoView.vue'
import HomeView from '@/components/home/HomeView.vue'

const routes = [

     {
          name: 'Clubs',
          path: '/clubs/:continent?/:country?/:region?/:club?/:scope?/:sub?/:subsub?',
          component: ClubsView
     },

     {
          name: 'Posts',
          path: '/posts/:continent?/:country?/:region?/:club?/:scope?/:sub?/:subsub?',
          component: PostsView
     },
     
     {
          name: 'Home',
          path: "/",
          component: HomeView
     },

     {
          name: 'Games',
          path: "/games/:competition?/:game?/:sub3?/:sub4?/:sub5?",  
          component: GamesView
     },

     {
          name: 'We',
          path: "/we/:group?/:view?",
          component: WeView
     },
     {
          name: 'Hockeydo',
          path: "/hockeydo/:view?",
          component: HockeydoView
     },






]

const router = createRouter({  
     history: createWebHistory(),
     routes
})

export default router
