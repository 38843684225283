<template>

     <div class="view box-shadow chat">

         

          <div class="shirts">  

               <div class="shirtcontainer">
                         <canvas class="shirt" :id="this.itemid.videoid + '_team1'"></canvas>
                         <div class="tekst">{{ translate.GAME_TEAM1 }}</div>
               </div>
                    
               <div class="shirtcontainer">
                         <canvas class="shirt" :id="this.itemid.videoid + '_team2'"></canvas>
                         <div class="tekst">{{ translate.GAME_TEAM2 }}</div>
               </div>   

          </div>     

          <div v-if="this.video">


               <div class="vimeocontainer">   
                    <iframe class="vimeo" :src="vimeosrc" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> 
               </div>  

          </div>     



          <h1>{{ translate.HOMECHATITEM_WATZOUJIJFLUITEN }}</h1>


          <router-link class="button floatright groen arrow-right mb10"  :to="{name: 'Games', params:{competition: this.itemid.competitionslug, game: 'game-' + this.itemid.game , sub3: 'game', sub4: 'video-1'  }}">{{translate.HOMEPLAYITEM_LEESVERDER}}</router-link>   

          

        
          
     </div>
     
</template>


<script>
import axios from "axios";



export default{
    
     data(){
          return {    
              video: null,  
              points: null,             
          }
     },
     props: ["itemid"], 


     methods: {

          getvideo() 
          {


               if (this.store.state.config.debug==1) 
               {
               console.log('getvideo: ' +  + this.itemid.videoid);
               }     

    
          
               var post = new Object();
               post.task = 'getvideo';
               post.videoid = this.itemid.firstvideoid;

          

               axios.post( this.store.state.config.apiserver + './home/HomeViewChatItem.php', post)
               .then(response => 
               {
                                            
                    //console.log(response.data);
                    this.video = response.data;
                 


               })
               .catch(error => 
               {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });
          
          },
          drawshirt1()
          {
               // Shirt 1 ---------------------------------------------------

          

               if (this.$store.state.config.debug==1) 
               {
               console.log('drawshirt1');
               }   

              

               var c=document.getElementById(this.itemid.videoid + '_team1');           


               var ctx=c.getContext("2d");

               c.width = 300;
               c.height = 150;


               ctx.clearRect(0, 0, 300, 150);
          
               ctx.save();
               ctx.miterLimit=4;
               ctx.scale(1.6,0.7);
               ctx.save();

               ctx.translate(-21.874997,-690.12578);
               ctx.save();

               ctx.strokeStyle="#000000";
               ctx.lineWidth=1;

               // links
               ctx.fillStyle = this.video.team_1_kleur_shirt_1;
               ctx.beginPath();
               ctx.moveTo(104.25,713.519);
               ctx.bezierCurveTo(115.512,715.031,124.894,714.663,133.5,713.519);
               ctx.lineTo(138.75,720.769);
               ctx.lineTo(76,853.269);
               ctx.lineTo(76,765.019);
               ctx.bezierCurveTo(69.259,765.77,63.658,765.219,52.5,771.019);
               ctx.bezierCurveTo(44.124,764.034,41.509,752.567,39,741.019);
               ctx.bezierCurveTo(64.95400000000001,729.349,78.005,727.7570000000001,99.25,721.269);
               ctx.lineTo(104.25,713.519);
               ctx.closePath();
               ctx.fill();
               ctx.stroke();


               // rechts

               ctx.fillStyle= this.video.team_1_kleur_shirt_2;
               ctx.beginPath();
               ctx.moveTo(133.5,713.519);
               ctx.lineTo(138.75,720.769);
               ctx.bezierCurveTo(161.394,729.352,174.516,728.415,198.75,741.769);
               ctx.bezierCurveTo(195.631,752.255,195.044,763.753,185.75,771.769);
               ctx.bezierCurveTo(178,765.681,170.25,766.969,162.5,765.019);


               ctx.lineTo(162.5,853.269);
               ctx.bezierCurveTo(133.372,858.572,104.463,859.9350000000001,76,853.269);

               ctx.closePath();
               ctx.fill();
               ctx.stroke();


               // kraagje links

               ctx.fillStyle= this.video.team_1_kleur_kraag;
               ctx.beginPath();
               ctx.moveTo(103.75,714.519);
               ctx.bezierCurveTo(106.49,722.582,110.43299999999999,729.2,116.5,733.269);
               ctx.lineTo(109.5,741.019);
               ctx.bezierCurveTo(101.767,735.082,101.32900000000001,728.3340000000001,99.25,721.769);
               ctx.fill();
               ctx.stroke();


               // kraag rechts

               ctx.beginPath();
               ctx.moveTo(133.75,714.019);
               ctx.bezierCurveTo(131.01,722.082,127.06700000000001,728.7,121,732.769);
               ctx.lineTo(128,740.519);
               ctx.bezierCurveTo(135.733,734.582,136.171,727.8340000000001,138.25,721.269);
               ctx.fill();
               ctx.stroke();



               // Knoopjes
               ctx.fillStyle="rgba(0,0,0,0)";
               ctx.strokeStyle="#000000";
               ctx.lineCap="round";
               ctx.font="   15px ";
               ctx.beginPath();
               ctx.moveTo(116.75,733.019);
               ctx.lineTo(120.5,733.019);
               ctx.lineTo(120.5,756.269);

               ctx.closePath();
               ctx.fill();
               ctx.stroke();
               ctx.restore();

          },

          drawshirt2()
          {

               // Shirt 2 ---------------------------------------------------


               if (this.$store.state.config.debug==1) 
               {
               console.log('drawshirt2');
               }   
               
               
               var c=document.getElementById(this.itemid.videoid + '_team2');           


               var ctx=c.getContext("2d");


               c.width = 300;
               c.height = 150;

               ctx.clearRect(0, 0, 300, 150);

               ctx.save();
               ctx.miterLimit=4;
               ctx.scale(1.6,0.7);
               ctx.save();

               ctx.translate(-21.874997,-690.12578);
               ctx.save();

               ctx.strokeStyle="#000000";
               ctx.lineWidth=1;



               // links
               ctx.fillStyle = this.video.team_2_kleur_shirt_1;
               ctx.beginPath();
               ctx.moveTo(104.25,713.519);
               ctx.bezierCurveTo(115.512,715.031,124.894,714.663,133.5,713.519);
               ctx.lineTo(138.75,720.769);
               ctx.lineTo(76,853.269);
               ctx.lineTo(76,765.019);
               ctx.bezierCurveTo(69.259,765.77,63.658,765.219,52.5,771.019);
               ctx.bezierCurveTo(44.124,764.034,41.509,752.567,39,741.019);
               ctx.bezierCurveTo(64.95400000000001,729.349,78.005,727.7570000000001,99.25,721.269);
               ctx.lineTo(104.25,713.519);
               ctx.closePath();
               ctx.fill();
               ctx.stroke();


               // rechts
               ctx.fillStyle= this.video.team_2_kleur_shirt_2;
               ctx.beginPath();
               ctx.moveTo(133.5,713.519);
               ctx.lineTo(138.75,720.769);
               ctx.bezierCurveTo(161.394,729.352,174.516,728.415,198.75,741.769);
               ctx.bezierCurveTo(195.631,752.255,195.044,763.753,185.75,771.769);
               ctx.bezierCurveTo(178,765.681,170.25,766.969,162.5,765.019);


               ctx.lineTo(162.5,853.269);
               ctx.bezierCurveTo(133.372,858.572,104.463,859.9350000000001,76,853.269);

               ctx.closePath();
               ctx.fill();
               ctx.stroke();


               // kraagje links

               ctx.fillStyle= this.video.team_2_kleur_kraag;
               ctx.beginPath();
               ctx.moveTo(103.75,714.519);
               ctx.bezierCurveTo(106.49,722.582,110.43299999999999,729.2,116.5,733.269);
               ctx.lineTo(109.5,741.019);
               ctx.bezierCurveTo(101.767,735.082,101.32900000000001,728.3340000000001,99.25,721.769);
               ctx.fill();
               ctx.stroke();


               // kraag rechts

               ctx.beginPath();
               ctx.moveTo(133.75,714.019);
               ctx.bezierCurveTo(131.01,722.082,127.06700000000001,728.7,121,732.769);
               ctx.lineTo(128,740.519);
               ctx.bezierCurveTo(135.733,734.582,136.171,727.8340000000001,138.25,721.269);
               ctx.fill();
               ctx.stroke();



               // Knoopjes
               ctx.fillStyle="rgba(0,0,0,0)";
               ctx.strokeStyle="#000000";
               ctx.lineCap="round";
               ctx.font="   15px ";
               ctx.beginPath();
               ctx.moveTo(116.75,733.019);
               ctx.lineTo(120.5,733.019);
               ctx.lineTo(120.5,756.269);

               ctx.closePath();
               ctx.fill();
               ctx.stroke();
               ctx.restore();

          },
          getvideostats()  
          {         



               if (this.$store.state.config.debug==1) 
               {
                    console.log('getvideostats');
               }                   

               this.userpointsteams = 0;
               this.userpointskaarten = 0;
               this.userpointsstraffen = 0;
               this.userpointsovertredingen = 0;
          
      
               var post = new Object();
               post.task = 'getvideostats';            
               post.videoid = this.itemid.firstvideoid;   
               post.hdaccesstoken = this.$cookie.get('hockeydo');
               
               // console.log(post);


               axios.post( this.$store.state.config.apiserver + './home/HomeViewChatItem.php', post)
               .then(response => {            

                    this.points = response.data;                      
                    // console.log(response.data);
                 

               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          },
 
                          



     },
     computed:{

          vimeosrc() {

               if (this.video)
               {

                    var vimeosrc = 'https://player.vimeo.com/video/'  + this.video.video_url.split('/')['3'];

                    return vimeosrc;
               } else {
                    return null
               }

          }
      

     },
     watch: {

 

          vimeosrc() {

               if (this.video !== null) 
               {
                    this.drawshirt1();
                    this.drawshirt2();
               }

          },



     },
     mounted(){



          this.getvideo();
          this.getvideostats();

     }
  

     
}
</script>

<style scoped>

.chat {
    position: relative; 
}

.vimeocontainer{
margin-top: -15px;     
margin-left: -15px;
margin-right: -15px;
position: relative;
padding-bottom: 60%;
margin-bottom: 10px;
}

.vimeocontainer iframe {
position: absolute;
top: 0;
right: 0;
left: 0;
bottom: 0;    
width: 100%;
height: 100%;
}


.shirts {
position: absolute;     
right: 10px;
top: 10px;
width: 110px;
z-index: 1;
background: rgba(0,0,0,0.1);
padding: 5px; 
}

.shirtcontainer {
width: 50%;
float: left;   
text-align: center; 
color: #fff;  

}

.shirtcontainer .tekst{
text-transform: lowercase;
}

.shirt {
width: 50px;
height: 50px;
margin-bottom: -10px;
}



</style>

