<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.panel==panelname" class="panel autoheight">

          <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>


               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.GAME_STRAF}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                

                    <div v-for="straf in straffen" :key="straf.id">  

                         <a v-on:click="setselected(straf)" class="button ghost mb10" >{{straf[this.$store.state.active.languagewebsite]}}</a>
                    
                    </div>

               </div>




          </div>

     </transition>
  
</template>

<script>




export default {

     data() {
          return{
               panelname: 'PlaysPlayStraffen'
    
          }
     },


     methods:{

          setselected(straf) 
          {
                      
               this.setgamesproperty('play_straf',straf);
               this.setactiveproperty('panel',null);
          },


         
    
          
          

     },

    computed: 
    {

          straffen() {

              const actives = new Array();

                       

               for (const item of this.store.state.games.straffen){     
                    
                    var active = new Object();

                    if (this.store.state.games.video.straffen === null) 
                    {
                         active = item;                                            

                         actives.push(active);  


                    } else 
                    {

                         if (this.store.state.games.video.straffen.includes(item.id))
                         {                              
                              active = item;                                              

                              actives.push(active);                
                         }   


                    }                              
                                              
               }        

          
               return actives;


              

 

          },

    },


  
}
</script>

