<template>
     <div class="mobilefooter" >
     
         
          
               <router-link :to="{name: 'Home'}">

               

                    <div v-if="section=='home'">

                         <a class="mainmenu_item">
                   
                         <i class="bi bi-house-fill"></i>
                      

                    </a>
                     

                    </div>

                    <div v-else>

                         <a class="mainmenu_item">
                        
                         <i class=" bi bi-house"></i>
                         
                    </a>

                    </div>

               
               </router-link>
       

                         
               <router-link  v-if="this.$store.state.games.competition" :to="{name: 'Games', params:{ competition: this.$store.state.games.competition.slug, game: 'game-' + this.$store.state.games.competition.latestgame, sub3: 'game'}}">

                    <div v-if="section=='games'">

                              <a class="mainmenu_item">
                                   <i class="     bi bi-play-fill"></i>
                            
                              </a>
                   
                    </div>

                    <div v-else>
                         <a class="mainmenu_item">
                              <i class="     bi bi-play"></i>
                      
                         </a>
                    </div>

               </router-link>
                         
               <router-link :to="{name: 'Posts', params:{ continent: currentContinentSlug, country: currentCountrySlug,region: currentRegionSlug,club: currentClubSlug,scope: currentScope,sub: 'posts',subsub: 'all' }}">
                    
               

                    <div v-if="this.$store.state.active.section=='posts'">
                              <a class="mainmenu_item  ">
                                   <i class="     bi bi-chat-square-text-fill"></i>
                                     
                              </a>

                         </div>

                    <div v-else>
                         <a class="mainmenu_item  ">
                              <i class="bi bi-chat-square-text"></i>
                              
                         </a>
                    </div>
               
               
               
               
               </router-link>

               <div v-if="this.store.state.current.club">
               

                    <router-link  :to="{name: 'Clubs', params:{ continent: currentContinentSlug, country: currentCountrySlug,region: currentRegionSlug,club: currentClubSlug,scope: 'clubinfo',sub: 'info', subsub: 'info' }}">
                         
                         

                              <div v-if="section=='clubs'">
                                   <a class="mainmenu_item  ">
                                        <i class="bi bi-shield-fill"></i>
                                   
                                   </a>

                              </div>

                              <div v-else>
                                   <a class="mainmenu_item  ">
                                        <i class="bi bi-shield"></i>
                              
                                   </a>
                              </div>


                    
     
                         
                         
                    
                    </router-link>


               </div>
     

               <router-link :to="{name: 'We' ,  params:{ group: 'referees', view: 'info' }}">
                    
               

                    <div v-if="section=='we'">
                         <a class="mainmenu_item  ">
                              <i class="bi bi-people-fill"></i>
                         
                         </a>

                    </div>

                    <div v-else>
                         <a class="mainmenu_item  ">
                              <i class="bi bi-people"></i>
                            
                         </a>
                    </div>
                    
               </router-link>
               
        
     
             
     
              
      
          
     
     
     </div>
     </template>
     
     <script>
     
     
     
     export default{
     name:'Footer',
     

     
     computed:
     {
          section() {
                         if (this.$store.state.active.section) {
                         return this.$store.state.active.section
                    } else {
                         return null
                    }
          },
     
     
          currentClubSlug() {
               if (this.$store.state.current.club) 
               {
                    return this.$store.state.current.club.slug;
                    } else {
                         
                         if ((this.$store.state.user) && (this.$store.state.user.club))
                         {
     
                         return this.$store.state.user.club.slug;
     
                         } else{
     
                         return '-';
                         }
                    }
               },
          currentRegionSlug() {
               if (this.$store.state.current.region) 
               {
                    return this.$store.state.current.region.slug;
               
               } else {
                    
                    if ((this.$store.state.user) && (this.$store.state.user.club))
                    {
     
                    return this.$store.state.user.club.region_slug;
     
                    } else{
     
                    return '-';
                    }
               }
          },
          currentCountrySlug() {
     
               if (this.$store.state.current.country) {
                    return this.$store.state.current.country.slug;
               } else {
                    
                    if ((this.$store.state.user) && (this.$store.state.user.club))
                    {
     
                    return this.$store.state.user.club.country_slug;
     
                    } else{
     
                    return '-';
                    }
               }
     
          },
          currentContinentSlug() {
     
               if (this.$store.state.current.continent) {
                    return this.$store.state.current.continent.slug;
               } else 
               {
                    
                    if ((this.$store.state.user) && (this.$store.state.user.club))
                    {

                         return this.$store.state.user.club.continent_slug;
     
                    } else{
     
                         return '-';
                    }
               }
     
          },
     
          currentScope() {     
     
     
               var scope = 'world';

               if (this.$store.state.current.continent) {
                    scope = 'continent';
               } 

               if (this.$store.state.current.country) {
                    scope = 'country';
               } 

               if (this.$store.state.current.region) {
                    scope = 'region';
               } 

               if (this.$store.state.current.club) {
                    scope = 'club';
               } 

               return scope;




          },
    
            
               
     
     
     
     },  
     
   
     
     
     }
     </script>
     
<style scoped>

.mobilefooter {
background: #fff;
width: 100%; 
display: flex;
justify-content: space-between; 
padding: 0px 0px;
-webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.05); 
box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.05);
}



.mobilefooter i {
padding: 10px;
color: var(--groen);
font-size: 26px;
padding: 10px;
}



</style>
     
     
     
     