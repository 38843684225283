export default {

    

     setgame(state,game){

          state.game = game
          
     },

     setcompetition(state,competition){

          state.games.competition = competition
          
     },
     setgamesproperty(state,payload){

          state.games[payload.property] = payload.value;
          
     },

     SET_RANKINGS(state,rankings){

          state.rankings = rankings
          
     }


}