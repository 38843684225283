
import axios from "axios";

import store from '../store';

export default {



     fetchrankings({commit})
          {
               this.rankings = null; 
            
               if (store.state.config.debug==1) 
               {
                    console.log('BOBBB fetchranking');
               }      
               
        
          

                    var post = new Object();
                    post.task = 'getrankingseason';
                    post.competition = store.state.route.competition;

                   
                    post.scope = store.state.route.sub4;
                    
                    if (store.state.current.club) {
                         post.clubid = store.state.current.club.id;
                    }

                    if (store.state.current.region) {
                         post.regionid = store.state.current.region.id;
                    }

                    if (store.state.current.country) {
                         post.countryid = store.state.current.country.id;
                    }

                    if (store.state.current.continent) {
                         post.continentid = store.state.current.continent.id;
                    }



       

                    // console.log(post);

                    axios.post( store.state.config.apiserver + './games/views/Rankings.php', post)
                    .then(response => 
                    {             
                         // console.log(response.data);          
                         
                         if (response.data.length > 0) 
                         {
                              this.rankingspresent = true;
                              
                              var rankings = response.data.sort(function (b, a) {
                              return a.points - b.points;
                              });

                         
                              var teller = 0;

                              var newrankings = [];

                              rankings.forEach(function (ranking) {

                                   teller++;

                                   ranking.ranking = teller;

                                   newrankings.push(ranking)
                                   
                                   
                              });

                         
                              this.rankings = newrankings;  

                         } else {

                              this.rankingspresent = false; 
                         }


                         commit('SET_RANKINGS',newrankings); 


                                             
                         

                    })
                    .catch(error => {
                         this.errorMessage = error.message;
                         console.error("There was an error!", error);
                    });

       
            

          },



    

     fetchcompetition({commit})
     {
          
          if(store.state.route.competition)
          {
               if (store.state.config.debug==1) 
               {
               console.log('fetch competition');
               }    

               var post = new Object();
               
               post.task = 'getcompetition';
               post.competitionslug = store.state.route.competition;

               // console.log(post);
               

     
               axios.post( store.state.config.apiserver + './games/GamesView.php',post)
               .then(response => 
               {
                    
                    // console.log(response.data);


                         //console.log(response.data) ;
                    const payload = new Object();
                    payload.property = 'competition';
                    payload.value = response.data;
               
                    commit('setgamesproperty',payload); 
                  
                    


               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          }     



     },
     
     fetchkaarten({commit}){

          if (store.state.config.debug==1) 
          {
          console.log('fetch kaarten');
          }   

          var post = new Object();
          post.task = 'getkaarten';

          axios.post( store.state.config.apiserver + './games/views/PlaysPlay.php', post)
          .then(response => 
          {
              
               const payload = new Object();
               payload.property = 'kaarten';
               payload.value = response.data;
          
               commit('setgamesproperty',payload); 
                     

          })
          .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
          });



     },
     fetchovertredingen({commit}){


          if (store.state.config.debug==1) 
          {
          console.log('fetch overtredingen');
          }   

          var post = new Object();
          post.task = 'getovertredingen';

          axios.post( store.state.config.apiserver + './games/views/PlaysPlay.php', post)
          .then(response => 
          {
            
               const payload = new Object();
               payload.property = 'overtredingen';
               payload.value = response.data;
          
               commit('setgamesproperty',payload);            

          })
          .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
          });

     },

     fetchstraffen({commit}){


          if (store.state.config.debug==1) 
          {
          console.log('fetch straffen');
          }   

          var post = new Object();
          post.task = 'getstraffen';

          axios.post( store.state.config.apiserver + './games/views/PlaysPlay.php', post)
          .then(response => 
          {
            
               const payload = new Object();
               payload.property = 'straffen';
               payload.value = response.data;
          
               commit('setgamesproperty',payload);            

          })
          .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
          });

     },
     fetchteams({commit}){


          if (store.state.config.debug==1) 
          {
          console.log('fetch teams');
          }   

          var post = new Object();
          post.task = 'getteams';

          axios.post( store.state.config.apiserver + './games/views/PlaysPlay.php', post)
          .then(response => 
          {
            
               const payload = new Object();
               payload.property = 'teams';
               payload.value = response.data;
          
               commit('setgamesproperty',payload);            

          })
          .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
          });

     },

     fetchgame({commit})
     {

          if (store.state.config.debug==1) 
          {
          console.log('fetch game');
          }    

  

          if(this.state.route.game)
          {

               var post = new Object();
               post.task = 'getgame';
               post.competition = this.state.route.competition;

               if (this.state.route.game && this.state.route.game.slice) 
               {
                    post.game = this.state.route.game.slice(5);
               }    
             


               if (store.state.user) {

                    post.hdaccesstoken = store.state.user.hdaccesstoken;
     
               }

     
               axios.post( store.state.config.apiserver + './games/GamesView.php',post)
               .then(response => 
               {
                    const payload = new Object();
                    payload.property = 'game';
                    payload.value = response.data;

               
                    commit('setgamesproperty',payload);   
                    
                    
     
     
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          }

          

   


     },
     fetchvideo({commit}) 
     {


          if (store.state.config.debug==1) 
          {
          console.log('fetch video');
          }     

     
          
          if (store.state.active.gamesvideoid)
          {
          
               var post = new Object();
               post.task = 'getvideo';
               post.videoid = store.state.active.gamesvideoid;

          

               axios.post( store.state.config.apiserver + './games/views/PlaysChat.php', post)
               .then(response => 
               {
                                            
                    // console.log(response.data);
                    const payload = new Object();
                    payload.property = 'video';
                    payload.value = response.data;
               
                    commit('setgamesproperty',payload);      


               })
               .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    });
          }          
          


     },
    





     
     
}


