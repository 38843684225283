<template>


     <div :class="openmap">

     <div id="map1" class="map-container"></div>



          <div class="filter">


               <div class="row mb5">
                    <div class="col-10 middle">
                    <div v-on:click="setactiveproperty('toppanel','FilterAreaAreaContinents')" class="select">{{continent.name}}</div>
                    </div>

                    <div class="col-2 middle-right">
                         <a class="bi bi-arrow-right-circle-fill tekstblauw" v-if="continent.id > 1" v-on:click="gotocontinent()"></a>
                    </div>

               </div>

               <div class="row mb5" v-if="continent.id>1">
                    <div class="col-10 middle">
                         <div v-on:click="setactiveproperty('toppanel','FilterAreaAreaCountries')" class="select">{{country.name}}</div>
                    </div>

                    <div class="col-2 middle-right">
                         <a  v-if="country.id>0" v-on:click="gotocountry()" class="bi bi-arrow-right-circle-fill tekstblauw"></a>
                    </div>
               </div>


               <div class="row mb5" v-if="country.id>0" >

                    <div class="col-10"  >

                         <div v-on:click="setactiveproperty('toppanel','FilterAreaAreaRegions')" class="select">{{region.name}}</div>

                    </div>

                    <div class="col-2 middle-right">

                         <a v-if="region.id>0" v-on:click="gotoregion()" class="bi bi-arrow-right-circle-fill tekstblauw"></a>
                    </div>

               </div>

               <div class="row clubs" v-if="region.id>0">

                    <div class="col-10 middle">

                         <div v-on:click="setactiveproperty('toppanel','FilterAreaAreaClubs')" class="select"> {{club.name_long}}</div>


                    </div>
                    <div class="col-2 middle-right" >

                         <a v-if="club.id>0" v-on:click="gotoclub()" class="bi bi-arrow-right-circle-fill tekstblauw"></a>
                    </div>

               </div>





          </div>

     </div>
</template>

<script>
import { mapState } from 'vuex';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import axios from "axios"

export default {
     data() {
          return {
          map: null,
          clubs: null,
          markers: [],
          currentclubid: null,
          clickedclub: null,
          clickmarker: null
          };
     },

     methods: {
          initMap()
          {


               this.map1 = L.map('map1',{ preferCanvas: true, zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true }).setView([0,0], 1); // Pas deze coördinaten aan naar je eigen voorkeur

               L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
               maxZoom: 19,
               attribution: '© OpenStreetMap contributors',
               }).addTo(this.map1);



          },
          getmarkers() {


               console.log('scope veranderd');


               if (this.currentscope=='world'){

                    this.getmarkersworld();

               }


               if (this.currentscope=='continent'){

                    this.getmarkerscontinent();

               }

               if (this.currentscope=='country'){



                    this.getmarkerscountry();

               }

               if (this.currentscope=='region'){

                    this.getmarkersregions();

               }


               if (this.currentscope=='club'){

                    this.getmarkersclubs();

               }

          },



          getmarkersworld(){


               if (this.$store.state.config.debug==1)
               {
               console.log('getmarkers world');
               }

               axios.get( this.$store.state.config.apiserver  + 'clubs/screens/Map.php', {
               params: {
               task : 'getmarkersWorld'

               }
               })
               .then(response => {


                    this.clubs = response.data;
                    // console.log(response.data);

                    this.map1.off();
                    this.map1.remove();
                    this.initMap();

                    this.placemarkers();

               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });



          },

          getmarkerscontinent(){

               {
                    console.log('getmarkers Continent');
               }


               axios.get( this.$store.state.config.apiserver + 'clubs/screens/Map.php', {
               params: {
               task : 'getmarkersContinent',
               continentid: this.$store.state.active.filterareacontinent.id
               }
               })
               .then(response => {


                    this.clubs = response.data;


                    this.map1.off();
                    this.map1.remove();
                    this.initMap();

                    this.placemarkers();

               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });



         },
          getmarkerscountry(){

               if (this.$store.state.config.debug==1)
                    {
                    console.log('getmarkers Country');
               }



               axios.get( this.$store.state.config.apiserver + 'clubs/screens/Map.php', {
               params: {
               task : 'getmarkersCountry',
               countryid: this.$store.state.active.filterareacountry.id
               }
               })
               .then(response => {


                    this.clubs = response.data;

                    this.map1.off();
                    this.map1.remove();
                    this.initMap();

                    this.placemarkers();




               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });



          },
          getmarkersregions(){

               if (this.$store.state.config.debug==1)
               {
               console.log('getmarkers Region');
               }

               this.clickedclub = null;


               axios.get( this.$store.state.config.apiserver + 'clubs/screens/Map.php', {
               params: {
               task : 'getmarkersRegion',
               regionid: this.$store.state.active.filterarearegion.id
               }
               })
               .then(response => {

                    this.clubs = response.data;

                    this.map1.off();
                    this.map1.remove();
                    this.initMap();

                    this.placemarkers();

               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });



          },
          getmarkersclubs(){


               this.clickedclub = null;

               if (this.$store.state.config.debug==1)
               {
                         console.log('getmarkers Club');
               }

               axios.get( this.$store.state.config.apiserver + 'clubs/screens/Map.php', {
               params: {
               task : 'getmarkersClub',
               clubid: this.$store.state.active.filterareaclub.id
               }
               })
               .then(response => {


                    this.clubs = response.data;
                    // console.log(response.data);

                    this.map1.off();
                    this.map1.remove();
                    this.initMap();

                    this.placemarkers();

               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          },
          placemarkers()
          {
               //https://stackoverflow.com/questions/50734061/performance-issue-vue-with-leaflet-and-leaflet-markercluster-thousands-markers

               if (this.$debug==1)
               {
               console.log('placemarkers');
               }



               const markers = L.markerClusterGroup({
               chunkedLoading: true,
               maxClusterRadius: 50
               });

               var bounds = L.latLngBounds();

               this.clubs.forEach(club =>
               {


                    var geoposition = club.geoposition.split(',');
                    var lat = geoposition['0'];
                    var lng = geoposition['1'];


                    if ((lat >= -90)&&(lat <= 90)&&(lng >= -180)&&(lng <= 180))
                    {


                         var myIcon = L.icon({
                              iconUrl: 'https://www.hockeydo.com/images/marker.png',
                              iconSize: [40, 40],
                              iconAnchor: [20, 40],
                              popupAnchor: [-3, -76]
                              });


                         if (club.noclub=="1")
                         {

                              myIcon = L.icon({
                              iconUrl: 'https://www.hockeydo.com/images/marker-rood.png',
                              iconSize: [40, 40],
                              iconAnchor: [20, 40],
                              popupAnchor: [-3, -76]
                              });

                              //console.log(club);
                         }


                         const marker = L.marker([lat, lng],{icon: myIcon});


                         marker.on('click', () => {

                           this.clickonmarker(club);
                         });


                         markers.addLayer(marker);
                         bounds.extend(marker.getLatLng());


                    }



               });

               this.map1.addLayer(markers); // Voeg de cluster groep toe aan de kaart


               setTimeout(() => {
                    this.map1.fitBounds(bounds, { animate: false });
               }, 500); //




          },





          clickonmarker(club) {

            

               this.setactiveproperty('screen',null);
               this.$router.push({ name: 'Clubs', params:{ continent: club.continentslug, country: club.countryslug,region: club.regionslug,club: club.slug,scope: 'clubinfo',sub: 'info', subsub: 'info' } })


          },



          gotocontinent() {

          if (this.$store.state.current.filterContinent==null)
          {
               // We zijn in de wereld
               this.$router.push({ name: 'Posts', params:{ continent: '-', country: '-',region: '-',club: '-',scope: 'world',sub: 'posts', subsub: 'all' } })

               this.setactiveproperty('panel','');
               this.setactiveproperty('screen',null);



          } else
          {
               var continentslug = this.$store.state.active.filterareacontinent.slug;
               this.$router.push({ name: 'Posts', params:{ continent: continentslug, country: '-',region: '-',club: '-',scope: 'continent',sub: 'posts', subsub: 'all' } })
               this.setactiveproperty('panel','');
               this.setactiveproperty('screen',null);


          }
          },
          gotocountry()
          {

               var continentslug = this.$store.state.active.filterareacontinent.slug;
               var countryslug = this.$store.state.active.filterareacountry.slug;

               this.$router.push({ name: 'Posts', params:{ continent: continentslug, country: countryslug,region: '-',club: '-',scope: 'country',sub: 'posts', subsub: 'all' } })
               this.setactiveproperty('panel','');
               this.setactiveproperty('screen',null);


          },
          gotoregion()
          {
               var continentslug = this.$store.state.active.filterareacontinent.slug;
               var countryslug = this.$store.state.active.filterareacountry.slug;
               var regionslug = this.$store.state.active.filterarearegion.slug;

               this.$router.push({ name: 'Posts', params:{ continent: continentslug, country: countryslug,region: regionslug,club: '-',scope: 'region',sub: 'posts', subsub: 'all' } })

               this.setactiveproperty('toppanel','');
               this.setactiveproperty('screen',null);


          },
          gotoclub()
          {

               var continentslug = this.$store.state.active.filterareacontinent.slug;
               var countryslug = this.$store.state.active.filterareacountry.slug;
               var regionslug = this.$store.state.active.filterarearegion.slug;
               var clubslug = this.$store.state.active.filterareaclub.slug;

               this.$router.push({ name: 'Posts', params:{ continent: continentslug, country: countryslug,region: regionslug,club: clubslug,scope: 'club',sub: 'posts', subsub: 'all' } })

               this.setactiveproperty('panel','');
               this.setactiveproperty('screen',null);


          }
     },
     computed:
     {
          ...mapState(['active']),

          openmap(){

               if(this.store.state.active.screen=='Map'){

                    return 'open';
               } else {
                
                    return 'close';
                    
               }

          },


          filterareaclub(){

               return this.$store.state.active.filterareaclub;
          },

          filterarearegion(){
               return this.$store.state.active.filterarearegion;
          },

          filterareacountry(){

               return this.$store.state.active.filterareacountry;
          },

          filterareacontinent(){

               return this.$store.state.active.filterareacontinent;
          },



          currentscope(){

               if (this.$store.state.active.filterareaclub) {

                    return 'club';
               }


               if (this.$store.state.active.filterarearegion) {

                    return 'region';
               }


               if (this.$store.state.active.filterareacountry) {

                    return 'country';
               }


               if (this.$store.state.active.filterareacontinent) {

                    if (this.$store.state.active.filterareacontinent.id==0) {

                         return 'world'
                    } else {


                         return 'continent';
                    }
               } else {

                    return 'world'

               }




          },
          continent(){

               if (this.$store.state.active.filterareacontinent)
               {
                    return this.$store.state.active.filterareacontinent;

               } else
               {

                    const continent = {};
                    continent.name = this.translate.FILTER_KIESCONTINENT;
                    continent.id = 0;
                    return continent
               }

          },


          country(){

          if (this.$store.state.active.filterareacountry)
          {

                    const country = this.$store.state.active.filterareacountry;
                    country.name = this.$store.state.active.filterareacountry[this.$store.state.active.languagewebsite];
                    return country;

               } else
               {
                    const country = {};
                    country.id = 0;
                    country.name = this.translate.FILTER_KIESLAND;
                    return country;
               }


          },

          region() {

          if (this.$store.state.active.filterarearegion) {

          return this.$store.state.active.filterarearegion

          } else
          {


          const region = {};
          region.name = this.translate.FILTER_KIESREGIO;
          region.id = 0;
          return region;
          }




          },


          club() {

          if (this.$store.state.active.filterareaclub) {

          return this.$store.state.active.filterareaclub;

          } else
          {
          const club = {};
          club.id = 0;
          club.name_long = this.translate.FILTER_KIESCLUB;
          return club;
          }



          },
          section(){
          return this.$route.name;

          }






     },

     watch: {


          currentscope(){


               this.getmarkers();

          },

         filterareaclub(){

               this.getmarkers();
          },

          filterarearegion(){
               this.getmarkers();
          },

          filterareacountry(){

               this.getmarkers();
          },

          filterareacontinent(){

               this.getmarkers();
          },


     },

     mounted() {


          this.$nextTick(() => {
               this.initMap();
          });

     },


};


</script>

<style>
#map1 {
     position: fixed;
     left: 0px;
     right: 0;
     top: 0px;
     bottom: 0px;
     background: #AAD3DF;
     opacity: 1;
     z-index: 2;
     transition: opacity 0.1s;
     display: block;
}

.close #map1{
     opacity: 0;
     z-index: -100;

}

.filter {

     position: fixed;
     top: 100px;
     left: 30px;
     z-index: 3;


}

@media (max-width: 576px) {

     .filter {

     position: fixed;
     top: 80px;
     left: 15px;
     z-index: 3;


     }

}

.filter .bi{
position: relative;
left: 15px;
}

.close .filter{

     opacity: 0;
z-index: -10;

}




</style>
