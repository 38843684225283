<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.toppanel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Toppanel | {{panelname}}</div>

               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.FILTER_KIESLAND}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div v-for="country in countries" :key="country.id">                          
                         <a class="ghost button mb10" v-on:click="setcountry(country)">{{getcountry(country)}}</a>
                    </div>


          
               </div>





          </div>

     </transition>
  
</template>

<script>

import axios from "axios";
export default {

 
     data() {
          return{
               panelname: 'FilterAreaAreaCountries',
               countries: null
          
               
          }
     },

     components: {


     },
     methods:{

          getcountry(country) {
               return country[this.$store.state.active.languagewebsite];
          },

          setcountry(country) {
               
               this.setactiveproperty('filterareacountry',country);

               this.setactiveproperty('filterarearegion',null);
               this.setactiveproperty('filterareaclub',null);
               this.setactiveproperty('toppanel','');

          }


     },

    computed: 
    {
         currentFilterContinent() {

               return this.$store.state.active.filterareacontinent;

          }


    },

 
     watch: {

          currentFilterContinent()  {

                    
               // Wanneer het continentID verandert, de landen opnieuw ophalen 
               if (this.$store.state.config.debug==1) 
               {
               console.log('getcountries');                        
               }

               if (this.$store.state.active.filterareacontinent)
               {
                    
                    var post = new Object();
                    post.task = 'getcountries';
                    post.continentID = this.$store.state.active.filterareacontinent.id;


                    axios.post( this.$store.state.config.apiserver + 'clubs/panels/SearchFilter.php',post)
                    .then(response => {               
                         //console.log(response.data);
                         this.countries = response.data;

                    })
                    .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    });
               }
         
          },




     },     


  
}
</script>

 
<style scoped>

</style>
