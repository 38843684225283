<template>

     <div class="view"> 

     
          <div class="row view_header">

               <div  class="col-9 middle">
                    <h1 v-if="rankingnumber>0">{{translate.RANKINGS_RANKINGVOOR}} {{rankingnumber}} {{translate.RANKINGS_RANKINGNA}} {{ name }}</h1>
                    <h1 v-else> {{translate.RANKINGS_RANKING}} {{name }} B</h1>
               </div>

               <div class="col-3 middle-right">
                    <a v-on:click="setactiveproperty('panel','RankingsFilter')" class="button ghost">{{ translate.GAMES_FILTER }}</a>
                </div>    

          </div>    



          <div v-if="this.rankings">              
                     
               <div v-for="ranking in this.rankings" :key="ranking.id">

                    <Ranking :ranking="ranking"/>
               </div>

          </div>

          <div v-else>              
                     
               <h2>{{translate.RANKINGS_NORANKINGS}}</h2><br>

          </div>


    
          
     </div>
     
</template>


<script>
import axios from "axios";
import Ranking from '@/components/games/views/RankingsRanking.vue'

export default{
   
     data(){
          return {    
               response: null,
               rankings: null,
               rankingspresent: null,
               details: null     
          }
     },
     components: {
          Ranking,   
     },
     
     methods: 
     {

 
          getranking()
          {
               this.rankings = null; 
            
               if (this.$store.state.config.debug==1) 
               {
                    console.log('getranking');
               }      
               
        
          

                    var post = new Object();
                    post.task = 'getrankingseason';
                    post.competition = this.$route.params.competition;

                   
                    post.scope = this.$store.state.route.sub4;
                    
                    if (this.$store.state.current.club) {
                         post.clubid = this.$store.state.current.club.id;
                    }

                    if (this.$store.state.current.region) {
                         post.regionid = this.$store.state.current.region.id;
                    }

                    if (this.$store.state.current.country) {
                         post.countryid = this.$store.state.current.country.id;
                    }

                    if (this.$store.state.current.continent) {
                         post.continentid = this.$store.state.current.continent.id;
                    }



       

                    // console.log(post);

                    axios.post( this.$store.state.config.apiserver + './games/views/Rankings.php', post)
                    .then(response => 
                    {             
                         // console.log(response.data);          
                         
                         if (response.data.length > 0) 
                         {
                              this.rankingspresent = true;
                              
                              var rankings = response.data.sort(function (b, a) {
                              return a.points - b.points;
                              });

                         
                              var teller = 0;

                              var newrankings = [];

                              rankings.forEach(function (ranking) {

                                   teller++;

                                   ranking.ranking = teller;

                                   newrankings.push(ranking)
                                   
                                   
                              });

                         
                              this.rankings = newrankings;  

                         } else {

                              this.rankingspresent = false; 
                         }


                                             
                         

                    })
                    .catch(error => {
                         this.errorMessage = error.message;
                         console.error("There was an error!", error);
                    });

       
            

          },

         
          calculatepoints()
          {

               if (this.$store.state.config.debug==1) 
               {
               console.log('calculatepoints');
               }   
     

               var post = new Object();
               post.task = 'calculatepoints';

               //console.log(post);

               axios.post( this.$store.state.config.apiserver + './games/views/Rankings.php', post)
               .then(response => 
               {
                    this.response = response.data;
                    console.log(response.data);    
                    this.getranking();

               })
               .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });



          }




     },
          
     computed:{    
          
          scopechanges(){

               return this.$route.params.sub4;
          },
          clubLogoUrl() {
            
               return this.$store.state.config.logosurl + this.details.id + '.png'
             
               
          },
          name(){

               console.log("BOB" + this.$route.params.sub4);

               if (this.$route.params.sub4 == 'club')
               {
                    return this.$store.state.current.club.name_long;
               }

               if (this.$route.params.sub4 == 'region')
               {
                    return this.$store.state.current.club.region;
               }

               if (this.$route.params.sub4 == 'country')
               {
                    
                    return this.$store.state.current.country[this.$store.state.active.languagewebsite];

                    
               }

               if (this.$route.params.sub4 == 'continent')
               {
                    return this.$store.state.current.continent[this.$store.state.active.languagewebsite];
               }

               if (this.$route.params.sub4 == 'world')
               {
                    return this.translate.RANKINGS_DEWERELD;
               }

     

               return '';
              
          },
          routechanges() {
               return this.$route.params;

          },
          rankingnumber(){

               if (this.$store.state.user){

                    var userid = this.$store.state.user.id;

                    var teller = 0;
                    var number = '';

                    if (this.rankings) 
                    {
                         this.rankings.forEach(function (ranking) {
                              teller++;
                              if (ranking.id == userid) {
                                   number = teller;
                                   
                              }

                         });
                    }

                    return number;


               } else {

                    return null
               }

 
               
          }

  
      

          
     },
     watch: {

          scopechanges(){

               //this.getranking();
          },
          
          routechanges() {      

          

               if (this.$route.params.sub3=="ranking") {

                    
                    this.getranking();

                   
                    
               }

          },
         



     },

     mounted() {


          this.getranking();
       
          this.calculatepoints();

         

          

     }
}
</script>

<style scoped>






</style>
