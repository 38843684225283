<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.panel==panelname" class="panel autoheight">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>


               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.GAME_FLUITTEGEN}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div v-for="team in this.$store.state.games.teams" :key="team.id">  

                         <a v-on:click="setselectedteam(team)" class="button ghost mb10" >{{ team[this.$store.state.active.languagewebsite] }}</a>
                    
                    </div>

                

               </div>




          </div>

     </transition>
  
</template>

<script>




export default {


     data() {
          return{
               panelname: 'PlaysPlayTeams'
    
          }
     },


     methods:{

          setselectedteam(team) 
          {

               this.setgamesproperty('play_team',team);
               this.setactiveproperty('panel',null);
          },

         
          

     },

  
}
</script>

