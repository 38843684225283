<template>
     
     
     
     
     <div class="mobileheader" >   


               <router-link class="item" :to="{name: 'Hockeydo',params:{ view: 'info'} }">

                    <img :src="hockeydologokleinsrc" class="logo"/>

               </router-link>


               <div class="search">

                    <input v-model="searchstring" :placeholder="translate.SEARCH_ZOEKOPCLUB">                   
                    <i v-on:click="closesearch()" class="bi bi-x tekstgrijs"></i> 

               </div>


         
               <i v-on:click="openmap()" class="item tekstgroen bi bi-globe"></i>
 
               <i v-if="loggedin" class="item bi bi-bell-fill tekstgroen" v-on:click="setactiveproperty('panel','Notifications')" ></i> 


               <img v-if="loggedin" class="item profilepicture cursorpointer" v-on:click="setactiveproperty('panel','Menu')"  :src="profilepicturersrc" :title="this.$store.state.user.id">


               <i v-if="!loggedin" class="item bi bi-translate tekstgroen" v-on:click="setactiveproperty('panel','CommonWebsiteLanguage')" ></i> 
          
               <div v-if="!loggedin" class="item"><a  v-on:click="setactiveproperty('panel','Login')" class="button">{{translate.HEADER_LOGIN}}</a></div>
          
     
     

     </div>
          
     

</template>


<script>


import axios from "axios";


export default{
     name:"Header",
     data(){
          return {    
               latestcompetition: null,
             
          }
     },  

     methods: {

          openmap(){


               if (this.store.state.active.screen=="Map"){

                    this.setactiveproperty('screen',null);

               } else {
                    this.setactiveproperty('screen','Map');
               }

               

          },

          closesearch(){

               this.searchstring = '';
               this.setactiveproperty('searchstring','');
          },

          
          gotoownclub() {
               
               if (typeof this.$store.state.user.club !== 'undefined') {
                    var club = this.$store.state.user.club;

                    this.$router.push({ name: 'Posts', params:{ continent: club.continent_slug, country: club.country_slug,region: club.region_slug,club: club.slug,scope: 'club',sub: 'posts', subsub: 'all' } })

               }

               
          },



          getlatestcompetition() {      

               var post = new Object();
               post.task = 'getlatestcompetition';         


               axios.post( this.$store.state.config.apiserver + 'common/HeaderTop.php', post)
               .then(response => 
               {
                    this.latestcompetition = response.data; 
                    // console.log(response.data)     ;
               })
               .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });


          }






     },
     computed:{


          userclub() 
          {
               return this.$store.state.user.club;
          },  
          clubLogoUrl() {
               return this.$store.state.config.logosurl + this.$store.state.user.club.id + '.png'                    
          },
          profilepicturersrc() {
               return 'https://www.hockeydo.com/users/'  + this.$store.state.user.imagename + '.png';
          },         
          hockeydologokleinsrc() {
                return this.$store.state.config.logosurl + 'logo-klein.png';
          },
    
          
   
    
          searchstring: {
               get() {
               return this.$store.state.active.searchstring;
               },
               set(value) {

                    var obj = new Object();
                    obj.property = 'searchstring';  
                    obj.value = value;
                    this.$store.commit('setactiveproperty',obj);


               },
          },
          
          

          


     },
    

     
}
</script>

<style scoped>


.mobileheader {
display: flex;     
margin-right: -5px;
margin-left: 10px;
}


.logo {
height: 35px;   
width: auto;
}


.item {
    flex: 0 0 auto;   
    display: flex;
    align-items: center;
    justify-content: center;

}

.search {
    flex: 1 1 auto;  
    position: relative;
 
}



.mobileheader .search input {
position: absolute;
top: 10px;
left: 5px;
right: -5px;
width: auto;
}

.mobileheader .search input:focus{
background: #eee;     
}

.mobileheader .search input:focus::placeholder {
opacity: 0;
transition: opacity 0.3s ease;
}

.mobileheader .search .bi-x{
position: absolute;
right: 10px;
top: 10px;
font-size: 30px;
padding: 5px;
}



.mobileheader i{
padding: 13px 5px;
font-size: 30px;
}


.mobileheader .profilepicture {
padding: 10px;  
width: 57px;
height: 57px;
border-radius: 30px;
border: none;
}






</style>