<template>

     <transition name="slideup">

          <div v-if="panelopen" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>
              

               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.COMMENTS_OPMERKINGENKOP}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">


                    <div v-for="competition in competitions" :key="competition.id">    
                         
                                  
                         <a class="button ghost mb15" v-on:click="setcompetition(competition)">{{competition['title_' + languagewebsite]}}</a>  
                      
                    
                    
                    </div>

          
               </div>


       


          </div>

     </transition>
  
</template>

<script>

import axios from "axios";

export default {

 
     data() {
          return{
               panelname: 'GamesSelectCompetition',
               competitions : null
         
          
               
          }
     },

     components: {


     },
     methods:{


          getcompetitions() {


               if (this.$store.state.config.debug==1) 
               {
               console.log('getcompetitions');
               }    

               var post = new Object();
               post.task = 'getcompetitions';         


               axios.post( this.$store.state.config.apiserver + 'games/GamesHeaderCompetitions.php', post)
               .then(response => 
               {
                    this.competitions = response.data; 

                    // console.log(response.data)     ;
               })
               .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });


          },
          setcompetition(competition){

               // console.log(competition);

         
               this.$router.push({ name: 'Games', params:{ competition: competition.slug, game: 'game-' + competition.latestgame} })
               
               
               this.setactiveproperty('panel','');

        


          }



     },

     computed: 
     {

          panelopen(){


               if (this.$store.state.active.panel==this.panelname){

                    this.getcompetitions();
                    return true;

               } else {
                    return false;
               }


          }


     },

     watch: 
     {


     },
     created()
     {
      
               
     }


  
}
</script>

 
<style scoped>

</style>
