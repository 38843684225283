<template>

     <div class="submenu" v-if="this.$store.state.games.game"> 

     
          
          <a v-if="this.$store.state.games.competition" v-on:click="setactiveproperty('panel','GamesSelectCompetition')" class="season button mr15">{{this.$store.state.games.competition['title_' +  languagewebsite] }}</a>

          <div class="submenu_menu" v-if="thisgames">    

                       
               <span v-for="thisgame in thisgames" :key="thisgame.id">   

            


                    <span v-if="gamename==thisgame.game">

                      

                         <router-link  :to="{ name: 'Games', params:{ competition: this.$store.state.games.competition.slug, game: 'game-' + thisgame.game, sub3: 'game',sub4:'video-1'} }"><div class="submenu_menu_item first active">{{ translate.GAMESHEADER_GAME }} {{thisgame.game}}</div></router-link> 


                    </span>

                    <span v-else>
                         <router-link  :to="{ name: 'Games', params:{ competition: this.$store.state.games.competition.slug, game: 'game-' + thisgame.game, sub3: 'game',sub4: 'video-1'} }"><div class="submenu_menu_item first" >{{ translate.GAMESHEADER_GAME }} {{thisgame.game}}</div></router-link> 

                    </span>

                         
                   
         
               </span> 

          </div>    



          <div class="subsubmenu_menu" v-if="thisgames">    


               <div v-if="this.store.state.games.status=='chat'">     
                    <router-link   :to="{ name: 'Games', params:{ competition: this.$store.state.games.competition.slug, game: gameslug, sub3: 'game', sub4: 'video-1'} }"><div class="subsubmenu_menu_item" :class="(view=='play')?'active':''">{{translate.GAMESHEADER_CHAT}}</div></router-link> 
               
               </div>

               <div v-if="this.store.state.games.status=='play'">     
                    <router-link   :to="{ name: 'Games', params:{ competition: this.$store.state.games.competition.slug, game: gameslug, sub3: 'game', sub4: 'video-1'} }"><div class="subsubmenu_menu_item" :class="(view=='play')?'active':''">{{translate.GAMESHEADER_PLAY}}</div></router-link> 
               
               </div>

               <div v-if="this.store.state.games.status=='wait'">     
                    <router-link   :to="{ name: 'Games', params:{ competition: this.$store.state.games.competition.slug, game: gameslug, sub3: 'game', sub4: 'video-1'} }"><div class="subsubmenu_menu_item" :class="(view=='play')?'active':''">{{translate.GAMESHEADER_WAIT}}</div></router-link> 
               
               </div>

      
               
               <router-link v-if="this.store.state.current.club" :to="{ name: 'Games', params:{ competition: this.$store.state.games.competition.slug, game: gameslug, sub3: 'ranking', sub4: 'club'} }"><div class="subsubmenu_menu_item" :class="(view=='club')?'active':''">Top {{ this.store.state.current.club.name_short }}</div></router-link>  
               <router-link v-if="this.store.state.current.region" :to="{ name: 'Games', params:{ competition: this.$store.state.games.competition.slug, game: gameslug, sub3: 'ranking', sub4: 'region'} }"><div class="subsubmenu_menu_item" :class="(view=='region')?'active':''">Top {{ this.store.state.current.region.name }}</div></router-link>    
               <router-link v-if="this.store.state.current.country" :to="{ name: 'Games', params:{ competition: this.$store.state.games.competition.slug, game: gameslug, sub3: 'ranking', sub4: 'country'} }"><div class="subsubmenu_menu_item" :class="(view=='country')?'active':''">Top {{ this.store.state.current.country[this.$store.state.active.languagewebsite] }}</div ></router-link>    
               <router-link v-if="this.store.state.current.continent" :to="{ name: 'Games', params:{ competition: this.$store.state.games.competition.slug, game: gameslug, sub3: 'ranking', sub4: 'continent'} }"><div class="subsubmenu_menu_item" :class="(view=='continent')?'active':''">Top {{ this.store.state.current.continent[this.$store.state.active.languagewebsite] }}</div></router-link>      
               <router-link  :to="{ name: 'Games', params:{ competition: this.$store.state.games.competition.slug, game: gameslug, sub3: 'ranking', sub4: 'world'} }"><div class="subsubmenu_menu_item" :class="(view=='world')?'active':''">Top {{ translate.RANKINGS_WERELD }}</div></router-link>      

          </div>    
   
          
     </div>

     
</template>


<script>
import axios from "axios";

export default{

     data(){
          return {                 
               competitions: null,
               thisgames: null
                                    
          }
     },
 


     methods: {


  

          getgames() 
          {

               if (this.$store.state.config.debug==1) 
               {
               console.log('getgames');
               }    


               if (this.$route.params.competition) {


                    var post = new Object();
                    post.task = 'getgames';  
                    post.competition =  this.$route.params.competition;      

                    // console.log(post);


                    axios.post( this.$store.state.config.apiserver + 'games/GamesHeaderGames.php', post)
                    .then(response => 
                    {
                         this.thisgames = response.data; 
                       
                    })
                    .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);



                    });



               }


          }


     },

     computed:{   

          gamename(){


               if ( this.$route.params.game && this.$route.params.game.slice) {

                    return this.$route.params.game.slice('5');  

               } else {  
                    
                    return null;

               }

              


          },
          gameslug(){
               return this.$route.params.game;
          },
          
          view(){

               if  (this.$store.state.route.sub3=="play") {

                   return 'play'; 
               } else {

                    return this.$store.state.route.sub4;
               }
          },         
     
          
          competitionslugchanges() {
               return this.$route.params.competition;    
          },
          games(){
               return this.$store.state.games;
          },
          club(){

               if (this.$store.state.current.club){

                    return true
               } else {

                    return false
               }

          }

     },
     watch: {

          competitionslugchanges(){

               this.getgames();

          }

     },
     mounted(){

          this.getgames();
     }

   
}
</script>

<style scoped>

.season {
position: absolute;
right: -5px;
top: 5px;
}

.subsubmenu_menu_item.first  {
position: relative;
padding-left: 25px;
}

.subsubmenu_menu_item i{
position: absolute;
left: 0px;
top: 8px; 
font-size: 20px; 
}


</style>
