
import axios from "axios";

import store from '../store';

export default {


     fetchlanguages({commit}){



          if (store.state.config.debug==1){
               console.log('fetch languages');
          }

          var post = new Object();
          post.task = 'getlanguages';
          axios.post( store.state.config.apiserver + 'app.php',post)
          .then(response => 
          {
               //console.log(response.data);

               const payload = new Object();
               payload.property = 'languages';
               payload.value = response.data;
          
               commit('setstaticproperty',payload);   
               commit('increaseloading');


          })
          .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          });

     },
     fetchuseractivities({commit}) 
     {

  

          if (store.state.config.debug==1){
               console.log('fetch useractivities');
          }

          var post = new Object();
          post.task = 'getuseractivities';

          axios.post( store.state.config.apiserver + 'app.php',post)
          .then(response => 
          {          
               //console.log(response.data) ;

               const payload = new Object();
               payload.property = 'useractivities';
               payload.value = response.data;
          
               commit('setstaticproperty',payload); 
               commit('increaseloading');  

          })
          .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
          });

     },
     fetchuserfunctions({commit})
     {
          
   

          if (store.state.config.debug==1){
               console.log('fetch userfunctions');
          }

          var post = new Object();
          post.task = 'getuserfunctions';

          axios.post( store.state.config.apiserver + 'app.php',post)
          .then(response => {          
               //console.log(response.data) ;
          
               const payload = new Object();
               payload.property = 'userfunctions';
               payload.value = response.data;
          
               commit('setstaticproperty',payload); 
               commit('increaseloading');



          })
          .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
          });

     },
     fetchpostcats({commit}) {



          if (store.state.config.debug==1){
               console.log('fetch postcats');
          }

       
      

          var post = new Object();
          post.task = 'getpostcats';


          axios.post( store.state.config.apiserver + 'app.php',post)
          .then(response => {          

               //console.log(response.data) ;
               const payload = new Object();
               payload.property = 'postcats';
               payload.value = response.data;
          
               commit('setstaticproperty',payload); 
               commit('increaseloading');

          })
          .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
          });


     },

     fetchlocations({commit}) 
     {



          if (store.state.config.debug==1){
               console.log('fetch locations');
          }

          var post = new Object();
          post.task = 'getlocations';
          axios.post( store.state.config.apiserver + 'app.php',post)
          .then(response => {          


                //console.log(response.data) ;
                const payload = new Object();
                payload.property = 'locations';
                payload.value = response.data;
           
                commit('setstaticproperty',payload); 
                commit('increaseloading');
          
          

          })
          .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
          });

     },
     fetchtranslations({commit}) 
     {


          if (store.state.config.debug==1){
               console.log('fetch translations');
          }
  
          
          var language = store.state.active.languagewebsite;

          var post = new Object(); 
          post.task = 'gettranslations';

          axios.post(store.state.config.apiserver + 'app.php',post)
          .then(response => {
               
               var translate = new Object();      

               response.data.forEach(function (translation) 
               {    
                    translate[translation.code] = translation[language].replace(/(\\n)+/g, '<br />');           
               
               });            
               
               commit('settranslate',translate);
               commit('increaseloading');




          })
          .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
          });

   


     },
     fetchlatestcompetition({commit}){


   

          if (store.state.config.debug==1){
               console.log('fetch latestcompetition');
          }
     

          var post = new Object();           
          post.task = 'getlatestcompetition';    

          axios.post( store.state.config.apiserver + 'app.php',post)
          .then(response => 
          {
               
               commit('setcompetition',response.data);  
               commit('increaseloading');

          })
          .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
          });


     },
     fetchuser({commit,dispatch}) {



          if (store.state.config.debug==1){
               console.log('fetchuser');
          }

          const hdaccesstoken = document.cookie.split('; ').find(row => row.startsWith('hockeydo')).split('=')[1];


          var post = new Object();
          post.task = 'getuser';
          post.hdaccesstoken = hdaccesstoken

          axios.post( store.state.config.apiserver + 'app.php',post)
          .then(response => 
          {
               console.log('fetch user');

               var user = response.data;

               user.languagewrite = store.state.static.languages.find(obj => obj.id === user.languagewriteid);
               user.location = store.state.static.locations.find(obj => obj.id === user.locationid);



               commit('setuser',user);  

               dispatch('fetchuserclub');
               commit('increaseloading');


               var postlanguage = store.state.static.languages.find(obj => obj.id === store.state.user.languagewriteid);
               commit('setactiveproperty', { property: 'postlanguage', value: postlanguage });

               var postlocation = store.state.static.locations.find(obj => obj.id === store.state.user.locationid);
               commit('setactiveproperty', { property: 'postlocation', value: postlocation });
               commit('setactiveproperty', { property: 'filterlocation', value: postlocation });



               var languagewebsite = store.state.user.languagewebsite;

               commit('setactiveproperty', { property: 'languagewebsite', value: languagewebsite });

     
          
          
          })
          .catch(error => 
          {
               this.errorMessage = error.message;
              console.error("There was an error!", error);
          });


     },
     fetchcontinent({commit},continent) 
     {

 

          if (store.state.config.debug==1){
               console.log('fetchcontinent');
          }
     
          if (continent == '-') {

               commit('setCurrentContinent',null);  

          } else
          {

               var post = new Object();
               post.task = 'getcontinent';
               post.continentslug = continent;


               axios.post( store.state.config.apiserver + 'common/HeaderClubs.php', post)
               .then(response => 
               {
               var data = response.data;   

               
               commit('setCurrentContinent',data);    
               console.log('fetch continent');


               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });
          }


     },
     fetchcountry({commit},country) 
     {
      
          if (store.state.config.debug==1){
               console.log('fetch country');
          }
     
         
          if (country=='-') {

               commit('setCurrentCountry',null);  

          } else
          {

               var post = new Object();         
               post.task = 'getcountry';
               post.countryslug = country;
     

               axios.post( store.state.config.apiserver + 'common/HeaderClubs.php', post)
               .then(response => 
               {
                    var data = response.data;   
                    commit('setCurrentCountry',data);  
                    // console.log(data);                                                           

               })
               .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });


          }        
          


     },
     fetchregion({commit},region) 
     {
     
          if (store.state.config.debug==1){
               console.log('fetch region');
          }
          


          if (region == '-' )
          {
               commit('setCurrentRegion',null); 

          } else
          {

               var post = new Object();          
               post.task = 'getregion';
               post.regionslug = region;            
     

               axios.post( store.state.config.apiserver + 'common/HeaderClubs.php', post)
               .then(response => 
               {
                    var data = response.data;   
                    commit('setCurrentRegion',data); 
                    // console.log(data);                                       
                    

               })
               .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });

          }

          

     },
     fetchclub({commit},club) 
     { 
     
          if (store.state.config.debug==1){
               console.log('fetch club');
          }
          



          if ((club == '-') || (typeof club =='undefined'))
          {
               
               commit('setCurrentClub',null);

          } else 
          {

               var post = new Object();           
               post.task = 'fetchclub';
               post.clubslug = club;

               if (store.state.user) {
                    post.hdaccesstoken = store.state.user.hdaccesstoken;  
               }
               

               //console.log(post);
     

               axios.post( store.state.config.apiserver + 'app-actions.php', post)
               .then(response => 
               {
                    var data = response.data;   
                    commit('setCurrentClub',data);    
                    // console.log(data);

               })
               .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });

          }


     },
     fetchtimelinerules({commit}) 
     { 
     
          if (store.state.config.debug==1){
               console.log('fetch timelinerules');
          }
          



               var post = new Object();           
               post.task = 'fetchtimelinerules';
           

               //console.log(post);
     

               axios.post( store.state.config.apiserver + 'app-actions.php', post)
               .then(response => 
               {
                    
                    const payload = new Object();
                    payload.property = 'rules';
                    payload.value = response.data;
                    commit('sethomeproperty',payload); 
                   

               })
               .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });

      

     },
     fetchuserclub({commit}) {


               if (store.state.config.debug==1) {
                    console.log('fetch userclub');
               }
                    

              
               var post = new Object(); 
               post.task = 'getuserclub';   
               post.clubid = store.state.user.clubid;   


               axios.post( store.state.config.apiserver + 'app.php', post)
               .then(response => 
               {
                    
                 
                    commit('setUserClub',response.data);  
                   

               })
               .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });


               
            

          },







     
     
}


