<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.toppanel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Toppanel | {{panelname}}</div>
              

               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.FILTER_KIESCONTINENT}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">
                    <div v-for="continent in continents" :key="continent.id">  
                         <a class="ghost button mb10" v-on:click="setcontinent(continent)">{{continent.name}}</a>
                    </div>
               </div>



          </div>

     </transition>
  
</template>

<script>

import axios from "axios";

export default {

 
     data() {
          return{
               panelname: 'FilterAreaAreaContinents'
         
          
               
          }
     },

     components: {


     },
     methods:{

          setcontinent(continent) {


               console.log(continent);

               
               this.setactiveproperty('filterareacontinent',continent);
               this.setactiveproperty('filterareacountry',null);
               this.setactiveproperty('filterarearegion',null);
               this.setactiveproperty('filterareaclub',null);
            
               this.setactiveproperty('toppanel','');
              

          }

     },

    computed: 
    {


    },

    watch: 
    {

    },
    mounted() {

          // Continenten ophalen

          if (this.$store.state.config.debug==1) 
               {
               console.log('getcontinents');                        
               }


          var post = new Object();
          post.task = 'getcontinents';
  

          axios.post( this.$store.state.config.apiserver + 'clubs/panels/SearchFilter.php', post)
          .then(response => 
          {               
               //console.log(response.data);

               this.continents = response.data;

               var options = [];
               var lang = this.$store.state.active.languagewebsite;     

               var item = new Object(); 
               item.id = "0";
               item.name = this.translate.FILTER_DEWERELD                     
               options.push(item);


               this.continents.forEach(function (continent) 
               {
                    var item = new Object(); 
                    item.id = continent.id;
                    item.name = continent[lang];   
                    item.slug = continent.slug;                   
                    options.push(item);

                          

               });

               this.continents = options;

               // Als eerste de wereld laten zien.
               this.setcontinent({"id": "0","name": "The world"});


               

          })
          .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          });
          



     }


  
}
</script>

 
<style scoped>

</style>
