import { createApp } from 'vue'
import VueGtag from "vue-gtag"
import App from './App.vue'
import router from './router'
import store from './store/store'
import cookie from './plugins/cookie'

// https://github.com/MatteoGabriele/vue-gtag

const app = createApp(App);

// Global properties verplaatst naar store.js


app.use(cookie);
app.use(store);
app.use(router);

app.use(VueGtag, {
     config: { 
          id: "G-ZJJ5K5KTFP"      
     }

}, router)

export default function (Vue, { head }) {

     head.script.push({     
          src: 'https://www.instagram.com/embed.js',     
          async: true   
     })

}

// Global methods

app.mixin({
     
     
     methods: {

          setstaticproperty(property,value) {  

              
               this.$store.commit('setstaticproperty',{ property,value});

             
          }, 


          setgamesproperty(property,value) {  

              
               this.$store.commit('setgamesproperty',{ property,value});

               
          }, 


          setcurrentproperty(property,value) {  

               this.$store.commit('setcurrentproperty',{ property,value});

            
          }, 
   
      
          setactiveproperty(property,value) {  

               // console.log(property,value);
               this.$store.commit('setactiveproperty',{ property,value});

               //console.log(this.$store.this.$store.state.active.panel);
          },  
          getactiveproperty(property)  {

               return this.$store.state.active[property];

          },          
          getobjectbyid(array,id){

               if (array || id ){
                    return array.find(obj => obj.id === id);
               }    else {

                    return null
               }

               
          }
      


     },
     computed: 
     {
          // NIEUW
          
          store() 
          {        
               return this.$store;        
          },
   
          translate() 
          {        
               return this.$store.state.translate;        
          },
          loggedin()
          {

               if (this.$store.state.user) {

                    if (this.$store.state.user.id > 0) {
                         return true;
                    } else
                    {
                         return false;
                    }

               } else {

                    return false;
               }

              
          },  
          isadmin(){

               if (this.$store.state.user) {

                    if (this.$store.state.user.userrole == 2) {
                         return true;
                    } else
                    {
                         return false;
                    }

               } else {

                    return false;
               }
          },  
          languagewebsite(){
               return this.$store.state.active.languagewebsite;

          },  



     },
 
});


app.mount('#app');


window.app = app;
window.apiserver = "BOBBIE";



