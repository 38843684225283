<template>
     
     <transition name="slideup">

          <div v-if="this.$store.state.active.panel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>


               <div class="row panel_header middle">
                    <div class="col col-10">
                         <h1>{{translate.VIDEOSTATS_STATISTIEKEN}}</h1>
                    </div>    
                    <div class="col-2">
                         <a v-on:click="setactiveproperty('panel',null)"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>              

      

               <div v-if="points">    
     


                    <div class="panel_content">


               
                         <div>
                              <div class="row klein dik">
                              <div class="col col-4">
                                   
                              </div>     

                              <div class="col col-6 alignright">
                                   {{translate.VIDEOSTATS_SCHEIDSRECHTERSKEUZE}}
                              </div>  

                              <div class="col col-2 alignright">
                                   <div v-if="loggedin">
                                   {{translate.VIDEOSTATS_JOUWKEUZE}}
                                   </div>
                              </div>                    

                         </div>                   
                                   
                         <div v-if="pointsteams" class="mb15">

                              <div class="row klein dik">

                                   <div class="col col-12 mb5">
                                        {{translate.VIDEOSTATS_KOPTEAMS}}
                                   </div>                                                     

                              </div>  
                              
                              <div v-for="points in pointsteams" :key="points.id" class="row klein mb5">     
                                   
                                   <div class="col col-4">          
                                        {{ points.keuze  }} 
                                   </div>                                 
                                   
                                   <div class="col col-6 alignright">
                                        {{ points.scheidsrechter}}
                              
                                   </div>  

                                   <div class="col col-2 alignright">
                                        {{ points.user}}
                                   </div>       
               
                              </div>
                                   
                              
                         </div>   

                    
                          <div v-if="pointsovertredingen" class="mb15">


                              <div class="row klein dik">

                                   <div class="col col-12 mb5">
                                        {{translate.VIDEOSTATS_OVERTREDINGEN}}
                                   </div>                                                     

                              </div>  
                              
                              <div v-for="points in pointsovertredingen" :key="points.id" class="row klein mb5">     
                                   
                                   <div class="col col-4">          
                                        {{ points.keuze  }} 
                                   </div>                                 
                                   
                                   <div class="col col-6 alignright">
                                        {{ points.scheidsrechter}}
                              
                                   </div>  

                                   <div class="col col-2 alignright">
                                        {{ points.user}}
                                   </div>       
               
                              </div>
                                   
                              
                         </div>   


                        

                         <div v-if="pointskaarten" class="mb15">

                              <div class="row klein dik">

                                   <div class="col col-12 mb5">
                                        {{translate.VIDEOSTATS_KAARTEN}}
                                   </div>                                                     

                              </div>  
                              
                              <div v-for="points in pointskaarten" :key="points.id" class="row klein mb5">     
                                   
                                   <div class="col col-4">          
                                        {{ points.keuze  }} 
                                   </div>                                 
                                   
                                   <div class="col col-6 alignright">
                                        {{ points.scheidsrechter}}
                              
                                   </div>  

                                   <div class="col col-2 alignright">
                                        {{ points.user}}
                                   </div>       
                    
                              </div>
                                   
                         
                         </div>   

            

                         <div v-if="pointsstraffen" class="mb15">

                              <div class="row klein dik">

                                   <div class="col col-12 mb5">
                                        {{translate.VIDEOSTATS_STRAFFEN}}
                                   </div>                                                     

                              </div>  
                              
                              <div v-for="points in pointsstraffen" :key="points.id" class="row klein mb5">     
                                   
                                   <div class="col col-4">          
                                        {{ points.keuze  }} 
                                   </div>                                 
                                   
                                   <div class="col col-6 alignright">
                                        {{ points.scheidsrechter}}
                              
                                   </div>  

                                   <div class="col col-2 alignright">
                                        {{ points.user}}
                                   </div>       
                    
                              </div>
                                   
                         
                         </div>  


     

                              

                              <div v-if="loggedin" class="row">     
                                   
                                   <div class="col col-4">          
                                        
                                   </div>                                 
                                   
                                   <div class="col col-6 alignright dik">
                                        totaal                              

                                   </div>  

                                   <div class="col col-2 alignright dik">
                                        {{pointstotal}}
                                   </div>       

                              </div>
     
                    

                         </div>    

                    
               
                    </div>






               </div>

               <div v-else class="loadingpost">       
                    

               <div class="regel"></div>
               <div class="regel"></div>
               <div class="regel"></div>   
               <div class="regel"></div>
               <div class="regel"></div>
               <div class="regel"></div>    
               <div class="regel"></div>
               <div class="regel"></div>
               <div class="regel"></div>     

          
               
               </div>  

          </div>  
     </transition>     
   

  
</template>

<script>

import axios from "axios";

export default {

 
     data(){
          return {    
                  
               teams: null,       
               overtredingen: null,
               kaarten: null,          
               straffen: null,
               points: null,
               userpointsteams: null,
               userpointskaarten: null,
               userpointsstraffen: null,
               userpointsovertredingen: null,
               loading: null,
               minloading: 7,
               panelname: 'VideoStats'
          }
     },

     methods:{

          telpointsovertredingen(value) {
               this.userpointsovertredingen = parseInt(value);               
          },
          telpointskaarten(value) {
               this.userpointskaarten = parseInt(value);               
          },
          telpointsstraffen(value) {
               this.userpointsstraffen = parseInt(value);               
          },
          telpointsteams(value) {
               this.userpointsteams = parseInt(value);               
          },
        
          getvideostats()  
          {         



               if (this.$store.state.config.debug==1) 
               {
                    console.log('getvideostats');
               }                   

               this.userpointsteams = 0;
               this.userpointskaarten = 0;
               this.userpointsstraffen = 0;
               this.userpointsovertredingen = 0;
          
      
               var post = new Object();
               post.task = 'getvideostats';            
               post.videoid = this.$store.state.active.gamesvideoid;   
               post.hdaccesstoken = this.$cookie.get('hockeydo');
               
               // console.log(post);


               axios.post( this.$store.state.config.apiserver + 'games/panels/VideoStats.php', post)
               .then(response => {            

                    this.points = response.data;                      
                    console.log(response.data);
                 

               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          },


          

     },

    computed: 
    {
   
          
          loadingstats() {

               return parseInt((this.loading/this.minloading)*100) + '%';

          },


          pointstotal() {

               return this.userpointsteams + this.userpointskaarten + this.userpointsstraffen + this.userpointsovertredingen;          

          },

          pointsovertredingen() {

               const actives = new Array();    
               
               if (this.points) 
               {
               
                    var items = Object.entries(this.points.pointsovertredingen);
                    
                    for (const item of items)
                    {   
                         if (item['0']=='user')  { var userkeuzeid = item['1'].toString();  }

                    }         

                    if (this.loggedin) {
                         var keuzegeweest = false;
                    }
               
                    

                    for (const item of items)
                    {   

                         var active = new Object();
                         
                         if (item['0'] !='user') 
                         {

                              var keuzeid = item['0'];         
                              var value = item['1'];

                              var keuzenaam = this.$store.state.games.overtredingen.filter(function(v) {
                                   return v.id === keuzeid;
                              })[0][this.$store.state.active.languagewebsite]
                    
                    
                              // Heeft user dezelfde keuze gehad?                        
                              
                              if (keuzeid==userkeuzeid) 
                              {
                                   active.user = value;

                                   if (this.loggedin) 
                                   {
                                        keuzegeweest = true;
                                   }
               
                                                       
                                   this.telpointsovertredingen(value);                             

                              }


                              active.keuze = keuzenaam;
                              active.scheidsrechter = value + '%'

                              actives.push(active); 


                         }                 
                                        
                    }  
          
                    if (this.loggedin) 
                    {

                         if (keuzegeweest == false ) {
                         
                              var acti = new Object();


                              keuzenaam = this.overtredingen.filter(function(v) {
                                   return v.id === userkeuzeid;
                              })[0][this.$store.state.current.languageWebsite]

                              acti.keuze = keuzenaam;
                              acti.user = '0';

                              actives.push(acti); 

                         }

                    }

               }
               return actives;


          },
          pointskaarten() {

               const actives = new Array();                   
               
               

               if (this.points) 
               {
                    var items = Object.entries(this.points.pointskaarten);
                    
                    for (const item of items)
                    {   
                         if (item['0']=='user')  { var userkeuzeid = item['1'].toString();  }

                    }         

                    if (this.loggedin) 
                    {
                         var keuzegeweest = false;
                    }     

                    for (const item of items)
                    {   

                         var active = new Object();
                         
                         if (item['0'] !='user') 
                         {

                              var keuzeid = item['0'];         
                              var value = item['1'];

                              var keuzenaam = this.$store.state.games.kaarten.filter(function(v) {
                                   return v.id === keuzeid;
                              })[0][this.$store.state.active.languagewebsite]
                    
                    
                              // Heeft user dezelfde keuze gehad?                        
                              
                              if (keuzeid==userkeuzeid) 
                              {
                                   active.user = value;

                                   if (this.loggedin) 
                                   {
                                        keuzegeweest = true;  
                                   }     
                                   this.telpointskaarten(value);                       
                              }


                              active.keuze = keuzenaam;
                              active.scheidsrechter = value + '%'

                              actives.push(active); 


                         }                 
                                        
                    }  
          
                    if (this.loggedin) 
                    {

                         if (keuzegeweest == false ) {
                         
                              var acti = new Object();


                              keuzenaam = this.kaarten.filter(function(v) {
                                   return v.id === userkeuzeid;
                              })[0][this.$store.state.current.languageWebsite]

                              acti.keuze = keuzenaam;
                              acti.user = '0';

                              actives.push(acti); 

                         }
                    } 
               }         
               return actives;

          },
          pointsstraffen() {

               const actives = new Array();  

               if (this.points) 
               {

                                     
                    
                    var items = Object.entries(this.points.pointsstraffen);
                    
                    for (const item of items)
                    {   
                         if (item['0']=='user')  { var userkeuzeid = item['1'].toString();  }

                    }         
                    
                    if (this.loggedin) 
                    {              
                         var keuzegeweest = false;
                    }

                    for (const item of items)
                    {   

                         var active = new Object();
                         
                         if (item['0'] !='user') 
                         {

                              var keuzeid = item['0'];         
                              var value = item['1'];

                              var keuzenaam = this.$store.state.games.straffen.filter(function(v) {
                                   return v.id === keuzeid;
                              })[0][this.$store.state.active.languagewebsite]
                    
                    
                              // Heeft user dezelfde keuze gehad?                        
                              
                              if (keuzeid==userkeuzeid) 
                              {
                                   active.user = value;

                                   if (this.loggedin) 
                                   {
                                        keuzegeweest = true;
                                   }     

                                   
                                   this.telpointsstraffen(value);                       
                              }


                              active.keuze = keuzenaam;
                              active.scheidsrechter = value + '%'

                              actives.push(active); 


                         }                 
                                        
                    }  
          
                    if (this.loggedin) 
                    {

                         if (keuzegeweest == false ) {
                         
                              var acti = new Object();


                              keuzenaam = this.straffen.filter(function(v) {
                                   return v.id === userkeuzeid;
                              })[0][this.$store.state.current.languageWebsite]

                              acti.keuze = keuzenaam;
                              acti.user = '0';

                              actives.push(acti); 

                         }

                    }

               }
               return actives;

          },
          pointsteams() {

               const actives = new Array();          
               
      

               if (this.points) 
               {

                    var items = Object.entries(this.points.pointsteams);
                    
                    for (const item of items)
                    {   
                         if (item['0']=='user')  { var userkeuzeid = item['1'].toString();  }

                    }         

                    if (this.loggedin) 
                    {
                         var keuzegeweest = false;
                    }     

                    for (const item of items)
                    {   

                         var active = new Object();
                         
                         if (item['0'] !='user') 
                         {

                              var keuzeid = item['0'];         
                              var value = item['1'];

                              var keuzenaam = this.$store.state.games.teams.filter(function(v) {
                                   return v.id === keuzeid;
                              })[0][this.$store.state.active.languagewebsite]
                    
                    
                              // Heeft user dezelfde keuze gehad?                        
                              
                              if (keuzeid==userkeuzeid) 
                              {
                                   active.user = value;

                                   if (this.loggedin) 
                                   {
                                        keuzegeweest = true; 
                                   }
                                                                 
                                   this.telpointsteams(value);   
                                   
                         
                              }


                              active.keuze = keuzenaam;
                              active.scheidsrechter = value + '%'

                              actives.push(active); 


                         }                 
                                        
                    }  
          
                    if (this.loggedin) 
                    {

                         if (keuzegeweest == false ) {
                         
                              var acti = new Object();


                              keuzenaam = this.teams.filter(function(v) {
                                   return v.id === userkeuzeid;
                              })[0][this.$store.state.current.languageWebsite]

                              acti.keuze = keuzenaam;
                              acti.user = '0';

                              actives.push(acti); 

                         }

                    }

                    

               }
        
               
               return actives;
               


          },

          panelchanges(){

               return this.$store.state.active.panel;
          }

        
    },

     watch:
     {

          panelchanges(){

               if (this.$store.state.active.panel==this.panelname) {
                    this.getvideostats();
               }     

               
          }

     },

  
}
</script>

 
<style scoped>

</style>
