<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.panel==panelname" class="panel autoheight">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>


               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.GAME_OVERTREDING}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div v-for="overtreding in overtredingen" :key="overtreding.id">  
                         <a v-on:click="setselected(overtreding)" class="button ghost mb10" >{{overtreding[this.$store.state.active.languagewebsite]}}</a>
                    </div>

               </div>




          </div>

     </transition>
  
</template>

<script>




export default {


     data() {
          return{
               panelname: 'PlaysPlayOvertredingen'
    
          }
     },


     methods:{

          setselected(overtreding) 
          {
               this.setgamesproperty('play_overtreding',overtreding);
               this.setactiveproperty('panel',null);
          },


         
          
          

     },

    computed: 
    {

 

          overtredingen() {

              const actives = new Array();

               //console.log(this.video);                             

               for (const item of this.store.state.games.overtredingen){     
                    
                    var active = new Object();

                    if (this.store.state.games.video.overtredingen === null) 
                    {
                         active = item;                
                         actives.push(item);  


                    } else 
                    {

                         if (this.store.state.games.video.overtredingen.includes(item.id))
                         {                              
                              active = item;                   

                              actives.push(active);                
                         }   


                    }                              
                                              
               }        

          
               return actives;


              

 

          },

    },


  
}
</script>

