<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.panel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>
              

               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.SETUSERCLUB_KIESJECLUB}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <input v-model="search" :placeholder="translate.SEARCH_ZOEKOPCLUB">   


                    <div v-if="search">

                         <div v-if="searchresults.length>1" class="search" >
               
          
                              <div v-for="club in searchresults" :key="club">
                    

                                   <div v-on:click="setclub(club)" class="club"><span class="dik ">{{ club.name_long }} </span><span class="tekstgrijs"> &bull; {{ club.place}} &bull;  {{ club[this.$store.state.active.languagewebsite]}} </span></div>            
                              
                              </div>

                         </div>


                         <div v-if="searchresults.length<1" class="search">

                                   {{translate.SEARCH_NIETSGEVONDEN}}


                         </div>



                    </div>





     
               </div>




          </div>

     </transition>
  
</template>

<script>

import axios from "axios";
export default {

 
     data() {
          return{
               panelname: 'SetUserClub',
               search: '',
               searchresults: []
         
          
               
          }
     },

     components: {


     },
     methods:{

          setclub(club){
               
               this.setactiveproperty('userclub',club);
               this.setactiveproperty('toppanel','SetUserClubConfirmation');

             


          }



     },

     computed: 
     {
          searchstring(){

               return this.search;
          }

     },

    watch: {

          searchstring()
          {

               if (this.$store.state.config.debug==1) {
                         console.log('search');
                    }

               if(this.search.length>0)

               {

                    if (this.$store.state.config.debug==1) {
                         console.log('search');
                    }


                    var post = new Object();
                    post.task = 'searchclubs';
                    post.searchstring = this.searchstring;         

                    axios.post( this.$store.state.config.apiserver + 'common/Search.php', post)
                    .then(response => 
                    {                         
                         this.searchresults = response.data;
               
                    })
                    .catch(error => {
                         this.errorMessage = error.message;
                         console.error("There was an error!", error);
                    });

               }  else {

                    this.searchresults = [];
                    
               } 


          }



     },


  
}
</script>

 

<style scoped>




.club {
padding: 10px 5px;  
transition: all 0.4s;
border-bottom: 1px solid #eee;  
cursor: pointer;
}

 .search .club:hover{
background: #f8f8f8;     
}



/* SM */
@media (min-width: 576px) {  


}
 
/* MD */
@media (min-width: 768px) {  


     .middle{
          width: calc(100vw - 320px);
          max-width: 1000px;
          margin: 0 0;  
         
     }
          
    

  
          
}
 
/* LG */
@media (min-width: 992px) { 


     #searchcontainer{

          position: fixed;
          left: 0px;
          right: 0px;
          top: 60px;
      
          


     }

     #searchcontainer .search{
     margin: 0 auto;
     width: calc(100vw - 600px);
     max-width: 800px;
     }
     
         
    

}
 
/* XL */
@media (min-width: 1200px) {  
 
}

     

</style>
