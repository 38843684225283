<template>
     
     
     
     
     <div class="header" >   


          <div class="left">

               <router-link :to="{name: 'Hockeydo',params:{ view: 'info'} }">

                    <img :src="hockeydologogrootsrc" class="logo"/>

               </router-link>




          </div>     

          <div class="middle">


               <div class="search">

                    <input v-model="searchstring" :placeholder="translate.SEARCH_ZOEKOPCLUB">                  
                    <i v-on:click="closesearch()" class="bi bi-x tekstgrijs"></i> 

                    <i v-on:click="openmap()" class="tekstgroen bi bi-globe"></i>
            


               </div>


          </div>
          
          
          <div class="right">



               <div v-if="loggedin" class="user">
                    
                    
                    <img v-if="this.$store.state.user"  class="profilepicture cursorpointer" v-on:click="setactiveproperty('panel','UserProfile')"  :src="profilepicturersrc" :title="this.$store.state.user.id">

      
                    
           
                    <i class="icon bi bi-bell-fill tekstgroen" v-on:click="setactiveproperty('panel','Notifications')" ></i> 

        
     
                   <i class="icon bi bi-bookmark-fill tekstgroen" v-on:click="setactiveproperty('panel','Bookmarks')" ></i> 
                   <img v-if="userclub" class="clublogo cursorpointer" v-on:click="gotoownclub()" :src="clubLogoUrl" />

       
               </div>

               <div v-else>

                    <i class="icon bi bi-translate tekstgroen" v-on:click="setactiveproperty('panel','CommonWebsiteLanguage')" ></i> 
               
                    <a v-on:click="setactiveproperty('panel','Login')" class="button loginbutton">{{translate.HEADER_LOGIN}}</a>
               
               
               </div>  

          </div>     

     </div>
          
     

</template>


<script>


import axios from "axios";





export default{
     name:"Header",
     data(){
          return {    
               latestcompetition: null,
          
           
             
          }
     },  

     methods: {

          openmap(){


               if (this.store.state.active.screen=="Map"){

                    this.setactiveproperty('screen',null);

               } else {
                    this.setactiveproperty('screen','Map');
               }

               

          },

          closesearch(){

               this.searchstring = '';
               this.setactiveproperty('searchstring','');
          },

          
          gotoownclub() {
               
               if (typeof this.$store.state.user.club !== 'undefined') {
                    var club = this.$store.state.user.club;

                    this.$router.push({ name: 'Posts', params:{ continent: club.continent_slug, country: club.country_slug,region: club.region_slug,club: club.slug,scope: 'club',sub: 'posts', subsub: 'all' } })

               }

               
          },



          getlatestcompetition() {      

               var post = new Object();
               post.task = 'getlatestcompetition';         


               axios.post( this.$store.state.config.apiserver + 'common/HeaderTop.php', post)
               .then(response => 
               {
                    this.latestcompetition = response.data; 
                    // console.log(response.data)     ;
               })
               .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });


          }






     },
     computed:{




          userclub() 
          {
               return this.$store.state.user.club;
          },  
          clubLogoUrl() {
               return this.$store.state.config.logosurl + this.$store.state.user.club.id + '.png'                    
          },
          profilepicturersrc() {
               return 'https://www.hockeydo.com/users/'  + this.$store.state.user.imagename + '.png';
          },         
          hockeydologogrootsrc() {
                return this.$store.state.config.logosurl + 'logo-groot.png';
          },
    
          
   
    
          searchstring: {
               get() {
               return this.$store.state.active.searchstring;
               },
               set(value) {

                    var obj = new Object();
                    obj.property = 'searchstring';  
                    obj.value = value;
                    this.$store.commit('setactiveproperty',obj);


               },
          },
          
          

          


     },
    

     
}
</script>

<style scoped>

.header{  
position: relative;  
height: 60px;
}

.header .left{
position: absolute;
top: 0;
left: 0;
height: 60px;     
width: 280px;
}

.header .middle {
margin: 0 auto;   
width: 100%;
position: relative;
}



.header .right{
position: absolute;
right: 0px;
top: 0;
bottom: 0;
width: 280px;
}






.header .logo {
position: absolute;
top: 15px;
left: 25px;
width: 150px;     
height: auto;
}



.header .search{
padding-top: 10px; 
width: 100%;
}

.header .search input {
width: calc(100% - 90px);     
}

.header .search input:focus{
background: #eee;     
}

.header .search input:focus::placeholder {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.header .search .bi-x{
position: absolute;
right: 94px;
top: 10px;
font-size: 30px;
}

.header .search .bi-globe{
position: absolute;
right: 40px;
top: 10px;
font-size: 30px;
}

.header .search .bi-funnel{
position: absolute;
right: 4px;
top: 10px;
font-size: 30px;
}




.header .user {
padding: 0;     
overflow: hidden;
float: right;

}
.header .user .icon {
float: right;

padding: 10px 5px;

}



.header .profilepicture {
margin: 10px 5px;   
width: 35px;
height: 35px;
float: right;
}

.header .clublogo {
margin: 10px 5px;    
width: 35px;
height: 35px;
float: right;
}

.header i{
   
    padding: 5px;
}

.header .bi-bell-fill {
font-size: 30px; 
}

.header .bi-bookmark-fill {

font-size: 30px; 
}

.header .bi-translate {
position: absolute; 
right: 140px;
top: 8px;
font-size: 34px;
}




.header .loginbutton {
    position: absolute; 
    right: 15px;
    top: 10px;
  
}



/* SM */
@media (min-width: 576px) {  


}
 
/* MD */
@media (min-width: 768px) {  


     .header .middle{
          width: calc(100vw - 320px);
          max-width: 1000px;
          margin: 0 auto;  
         
     }
          
    

  
          
}
 
/* LG */
@media (min-width: 992px) { 
     .header .middle{
          margin: 0 auto;
          width: calc(100vw - 600px);
          max-width: 800px;
         
     }

}
 
/* XL */
@media (min-width: 1200px) {  
 
}



</style>