<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.panel==panelname" class="panel autoheight">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>


               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.GAME_KAART}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div v-for="kaart in kaarten" :key="kaart.id">  

                         <a v-on:click="setselected(kaart)" class="button ghost mb10" >{{kaart[this.$store.state.active.languagewebsite]}}</a>
                    
                    </div>

               </div>




          </div>

     </transition>
  
</template>

<script>




export default {

     data() {
          return{
               panelname: 'PlaysPlayKaarten'
    
          }
     },


     methods:{

          setselected(kaart) 
          {
                      
               this.setgamesproperty('play_kaart',kaart);
               this.setactiveproperty('panel',null);
          },



          
          

     },

    computed: 
    {

          kaarten() {

              const actives = new Array();

               //console.log(this.video);                             

               for (const item of this.store.state.games.kaarten){     
                    
                    var active = new Object();

                    if (this.store.state.games.video.kaarten === null) 
                    {
                         active = item;                                        

                         actives.push(active);  


                    } else 
                    {

                         if (this.store.state.games.video.kaarten.includes(item.id))
                         {                              
                              active = item;                                        
                              actives.push(active);                
                         }   


                    }                              
                                              
               }        

          
               return actives;


              

 

          },

    },


  
}
</script>

