<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.toppanel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Toppanel | {{panelname}}</div>

               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.FILTER_KIESCLUB}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div v-for="club in clubs" :key="club.id">  
                         
                    
                         <a class="ghost button mb10" v-on:click="setclub(club)">{{club.name_long}}</a>
                    </div>
               
               </div>


         



          </div>

     </transition>
  
</template>

<script>

import axios from "axios";

export default {

 
     data() {
          return{
               panelname: 'FilterAreaAreaClubs',
               clubs: null
         
          
               
          }
     },

     components: {


     },
     methods:{


          setclub(club) {
               
               this.setactiveproperty('filterareaclub',club)              
               this.setactiveproperty('toppanel','')

          },

     },

    computed: 
    {
          currentFilterRegion() {

               return this.$store.state.active.filterarearegion

          }

    },

   watch: {

               currentFilterRegion() {
               
               // Wanneer het regio id verandert, de nieuwe clubs ophalen.
               if (this.$store.state.config.debug==1) 
               {
               console.log('getclubs');                        
               }


               if (this.$store.state.active.filterarearegion)
               {
                    var post = new Object();
                    post.task = 'getclubs';
                    post.regionID = this.$store.state.active.filterarearegion.id

               
                    
                    axios.post( this.$store.state.config.apiserver + 'clubs/panels/SearchFilter.php', post)
                    .then(response => {               
                         
                         // console.log(response.data);
                         this.clubs = response.data;

                    })
                    .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    });

               }

          }




     },    


  
}
</script>

 
<style scoped>

</style>
