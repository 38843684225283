<template> 

     <transition name="slideup">

          <div v-if="this.store.state.active.panel=='AddClub'" class="panel">

     

               <div class="row panel_header middle">
                    <div class="col col-6">
                         <h1>{{translate.ADDCLUB_CLUBTOEVOEGEN}}</h1>
                    </div>    
                    <div class="col-6">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <form @submit="postdata">

                         <div class="row">

                              <div class="col col-12 mb15">
                                   {{translate.ADDCLUB_TOELICHTING}}  
                              </div>    


                              <div class="col col-12">                      
                                   <input v-model="post.naam" :class="[valideer, post.naam ? 'ok' : 'notok' ]"   :placeholder="[[translate.ADDCLUB_CLUBNAAM]]" />
                              </div>  


                              <div class="col col-12">                      
                                   <input v-model="post.straat" :class="[valideer, post.straat ? 'ok' : 'notok' ]" :placeholder="[[translate.ADDCLUB_STRAATENHUISNUMMER]]" />
                              </div>    


                              <div class="col col-12">                      
                                   <input v-model="post.plaats" :class="[valideer, post.plaats ? 'ok' : 'notok' ]" :placeholder="[[translate.ADDCLUB_PLAATS]]" />
                              </div>    


                              <div class="col col-12">                      
                                   <input v-model="post.regio" :class="[valideer, post.regio ? 'ok' : 'notok' ]" :placeholder="[[translate.ADDCLUB_REGIO]]" />
                              </div>       

                              <div class="col col-12">                      
                                   <input v-model="post.land" :class="[valideer, post.land ? 'ok' : 'notok' ]" :placeholder="[[translate.ADDCLUB_LAND]]" />
                              </div>  

                              <div class="col col-12">                      
                                   <input v-model="post.continent" :class="[valideer, post.continent ? 'ok' : 'notok' ]" :placeholder="[[translate.ADDCLUB_LAND]]" />
                              </div> 
                              
                              

                              <div class="col col-12">                      
                                   <input v-model="post.website" :class="[valideer, websitefacebooktwitter ? 'ok' : 'notok' ]" :placeholder="[[translate.ADDCLUB_WEBSITE]]" />
                              </div>    

                              <div class="col col-12">                      
                                   <input v-model="post.twitter" :class="[valideer, websitefacebooktwitter ? 'ok' : 'notok' ]" :placeholder="[[translate.ADDCLUB_TWITTER]]" />
                              </div>  

                              <div class="col col-12">                      
                                   <input v-model="post.facebook" :class="[valideer, websitefacebooktwitter ? 'ok' : 'notok' ]" :placeholder="[[translate.ADDCLUB_FACEBOOK]]" />
                              </div>    

                         

                              <div class="col col-12">                   
                                   <a href="https://www.latlong.net/" class="ghostbutton mb15" target="_NEW">{{translate.ADDCLUB_FINDLATLON}}</a>

                                   <input v-model="post.lat" :class="[valideer, post.lat ? 'ok' : 'notok' ]" :placeholder="[[translate.ADDCLUB_LAT]]" />
                              </div> 

                              <div class="col col-12">                      
                                   <input v-model="post.lon" :class="[valideer, post.lon ? 'ok' : 'notok' ]" :placeholder="[[translate.ADDCLUB_LONG]]" />
                                   
                              </div> 

                              <div class="col col-12">
                                        <button class="floatright" type="submit"> {{translate.ADDCLUB_VERZENDEN}}</button>
                              </div>      

                    

                         </div>

                    </form>     


               

          
               </div>

          </div>

     </transition>     
  
</template>

<script>

import axios from "axios";




export default {

 
     data() {
          return{

               post:{                
               },
               validate: false,
               errors: 0                                
               
          }
     },

 

     components: {
       


     },
     methods:{

          postdata(e) {

               e.preventDefault();


               this.validate = true;

               this.errors = 0;

               if (!this.post.naam) {  this.errors++ }
               if (!this.post.straat) {  this.errors++ }
               if (!this.post.plaats) {  this.errors++ }
               if (!this.post.regio) {  this.errors++ }
               if (!this.post.land) {  this.errors++ }
               if (!this.post.continent) {  this.errors++ }

               if (!this.post.lat) {  this.errors++ }
               if (!this.post.lon) {  this.errors++ }


               var websitefacebooktwitter = false
               if (this.post.website) {  websitefacebooktwitter = true }
               if (this.post.facebook) {  websitefacebooktwitter = true }
               if (this.post.twitter) {  websitefacebooktwitter = true }

               if (websitefacebooktwitter==false) {  this.errors++}

               console.log(this.errors);


               if (this.errors==0)
               {

                    if (this.$store.state.config.debug==1) 
                    {
                    console.log('addclub');
                    }

                    console.log(this.post);

                    this.post.task = 'addclub';
                    this.post.hdaccesstoken = this.$cookie.get('hockeydo'); 

                    axios.post(this.$store.state.config.apiserver + 'clubs/panels/Addclub.php',this.post)
                    .then(response => 
                    {           
                         
                         if (this.$store.state.config.debug==1) 
                         {
                         console.log(response.data);
                         }                                                         
                                                                         
                         this.setactiveproperty('panel','AddClubThankyou')

                    })
                    .catch(error => 
                    {
                         this.errorMessage = error.message;
                         console.error("There was an error!", error);
                    });



               }
            


   


          },     
          closepanel()
          {
               this.$store.dispatch('taskShowPanel',null);


          }
          

     },

    computed: 
    {


          taskShowPanel() 
          {              
               if (this.$store.state.task.showPanel=='AddClub') {
                    return true
               } else {
                    return false
               }               
          },

          translate() 
          {        
               return this.$store.state.translate;        
          },
          valideer() {
               
               if (this.validate == true) {
                    return 'valideer'
               } else {
                    return '';
               }
          },

          websitefacebooktwitter() {

               var ingevuld = false;
               if (this.post.website) { ingevuld = true  }
               if (this.post.twitter) { ingevuld = true  }
               if (this.post.facebook) { ingevuld = true  }

               return ingevuld;

          }

    },

    watch: 
    {

    }


  
}
</script>

 
<style scoped>

</style>
