<template>




     <div v-if="user">

     

          <div v-if="this.$store.state.active.rankingsShowReferees && user.userstatus=='admitted'" >      

          
          
                    <div class="row mb15">

                         <div class="col-10 middle">

                              <h2 class="mr10">{{ranking.ranking}}</h2>

                              <img class="profilepicture"  :src="profilepicture" :title="user.lastname">


                              <div class="tekstgrijs klein">  


                              <i  class="bi bi-shield-shaded mr5"></i> 
                              <span class="dik tekstzwart">{{user.userFirstname}} {{user.userLastname}}</span> &bull; 

                              {{user.clubNamelong}} &bull; {{user.clubPlace}}
                              {{clubcountry}} &bull; {{clubcontinent}}
                              </div>

                         </div>     


                         <div class="col-2 klein dik middle-right">{{ranking.points}}</div> 



                    </div>
               
          </div>

          <div v-if="this.$store.state.active.rankingsShowUsers && user.userstatus!='admitted'" >      

          
          
               <div class="row mb15">

                    <div class="col-10 middle">

                         <h2 class="mr10">{{ranking.ranking}}</h2>

                         <img class="profilepicture"  :src="profilepicture" :title="user.lastname">


                         <div class="tekstgrijs klein">  


                         <span class="dik tekstzwart">{{user.userFirstname}} {{user.userLastname}}</span> &bull; 

                         {{user.clubNamelong}} &bull; {{user.clubPlace}}
                         {{clubcountry}} &bull; {{clubcontinent}}
                         </div>

                    </div>     


                    <div class="col-2 klein dik middle-right">{{ranking.points}}</div> 



               </div>

          </div>


          

     </div>




     <div v-else class="loadingpost">
                  
          <div class="regel"></div>             
    

     </div>     


   
     
</template>


<script>
import axios from "axios";

export default{
     name:"PostsHeader",
     data(){
          return {    
              user: null                
          }
     },
     props: ["ranking"]
     , 
     computed:{          
   
     
          profilepicture () {                      
               return 'https://www.hockeydo.com/users/'  + this.user.userimagename + '.png';

          },
          clubcountry() {
               return this.user['clubCountry' + this.$store.state.active.languagewebsite];
          },
          clubcontinent() {
               return this.user['clubContinent' + this.$store.state.active.languagewebsite];
          },
          isreferee() {       

               if (this.user.userrefereestatus=="admitted") {
                    return true
               } else
               {    return false

               }
          },
          showusers() {
               return this.$store.state.active.rankingsShowUsers;
          },
          showreferees() {
               return this.$store.state.active.rankingsShowReferees;
          }




          
     },

     mounted() {            
     
               if (this.$store.state.config.debug==1) 
               {
                    console.log('getuser');
               }

    
               var post = new Object();
               post.task = 'getuser';  
               post.userid = this.ranking.id;
                     
               
               axios.post( this.$store.state.config.apiserver + './games/views/RankingsRanking.php', post)
               .then(response => 
               {                                
                    this.user = response.data;      
                    console.log(response.data); 

               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });
     

     
     
     }
}
</script>

