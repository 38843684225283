<template>


     <div v-if="this.$store.state.games.competition"> 

          <GamesHeader/>  


          <div v-if="view=='game'">
               <Play v-if="gamestatus=='play'" />
               <Chat v-if="gamestatus=='chat'"/>
               <Wait v-if="gamestatus=='wait'"/>
           </div>

           <div v-if="view=='ranking'">
               <Rankings />
           </div>

       
          
     </div>
     
</template>


<script>

import GamesHeader from '@/components/games/GamesHeader.vue'
import Play from '@/components/games/views/Play.vue'
import Chat from '@/components/games/views/Chat.vue'
import Wait from '@/components/games/views/Wait.vue'
import Rankings from '@/components/games/views/Rankings.vue'



export default{

     data(){
          return {    
             
          }
     },
     components: {
          GamesHeader,    
          Play,
          Chat,
          Wait,
          Rankings
     },

     computed: {

          view(){

               if (this.$route.params.sub3=="game") {
                    return 'game'
               } else {

                    return this.$route.params.sub3
               }

               

          },


          competitionroutechanges(){

               return this.$route.params.competition;
   
          },
       
          gameroutechanges(){
               return this.$route.params.game;
          },

          gamechanges(){
               return this.$store.state.games.game;
          },

          videochanges(){
               return this.$store.state.route.sub4;
          },


          gamestatus(){

               // console.log(this.games.game);

               
               if (this.$store.state.games.game){

                    var status = '';

                    if (this.$store.state.games.game.status=="play") {
                         status = "play";


                         if (this.$store.state.games.game.numberofvideosplayedtobeplayed=="0") {
                         status = "wait";
                         }
 
                    
                    }

                    if (this.$store.state.games.game.status=="chat") {
                         status = "chat";
                    }


                    if (this.$store.state.games.game.forcestatus=="play") {
                         status = "play";
                    }
                    if (this.$store.state.games.game.forcestatus=="chat") {
                         status = "chat";
                    }

 
                    this.setgamesproperty('status',status);
               
                    
                    return status;

               } else {

                    return null
               }

 
          },

    

     },
     watch:{

          competitionroutechanges(){     
               
               
               this.$store.dispatch('fetchcompetition'); 

          },      
         
          gameroutechanges(){

               this.$store.dispatch('fetchgame'); 
          },

          gamechanges(){

               if (this.gamestatus=="play") 
               {

                    var videonumber = this.$store.state.games.game.video_start;

                    var videoid = this.$store.state.games.game['video_' + videonumber];

                    this.setactiveproperty('gamesvideoid',videoid);
                    this.setactiveproperty('gamesvideonumber',videonumber);

                    this.$store.dispatch('fetchvideo');

               }

               if (this.gamestatus=="chat") 
               {
                    if (this.$route.params.video && this.$route.params.video.slice) {
                         videonumber = this.$route.params.video.slice('6');

                    } else {
                         videonumber = 1;
                    }
                    
                    
                    videoid = this.$store.state.games.game['video_' + videonumber];

                    this.setactiveproperty('gamesvideoid',videoid);
                    this.setactiveproperty('gamesvideonumber',videonumber);

                    this.$store.dispatch('fetchvideo');

               }

        

          },

          videochanges()
          {
               

               if (this.$store.state.route.sub4)
               {
          
                    if (this.$store.state.route.sub4.slice){
                         var videonumber = this.$store.state.route.sub4.slice('6');

                    }
                    
                    var videoid = this.$store.state.games.game['video_' + videonumber];



                    this.setactiveproperty('gamesvideoid',videoid);
                    this.setactiveproperty('gamesvideonumber',videonumber);

                    this.$store.commit('deletepostsids','')
                    
                    this.$store.dispatch('fetchvideo');

               }
         
          }





    

     },
     mounted(){
          
          

          this.$store.dispatch('fetchcompetition'); 

          this.$store.dispatch('fetchgame'); 
 

     

     }       
  
}







</script>
